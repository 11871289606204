<template>
	<div id="nav">
    <div class="nav1">
  		<div class="logo"><a v-scroll-to="'#portada'"><img src="../assets/pas-e_.png"></a></div>
  		<div class="menu">
  			<a v-for="item in items" :class="item.class" v-scroll-to="item.link" @click="activar(item.key)" :active="item.active">
  				<svg v-if="item.active" width="15" height="15"><rect width="15" height="15" style="fill:#148979"/></svg>
  				{{item.text[lang]}}
  			</a>
  		</div>
    </div>
    <div class="menulang pc">
      <!-- <button v-for="item in idiomas" v-html="item.text" @click="changeLang(item.lang)" 
      :class="{active: item.lang==lang}"></button> -->
      <router-link class="botonlang" v-for="item in idiomas" v-html="item.text" :to="item.lang" 
      :class="{active: item.lang==lang}"></router-link>
    </div>
    <div class="menulang phone">
      <!-- <div class="actual" @click="showDrop">{{lang}}</div> -->
      <!-- <div class="drop" v-if="dropphone"> -->
        <router-link class="botonlang" v-for="item in idiomas" v-html="item.text" :to="item.lang" 
      :class="{active: item.lang==lang}"></router-link>
     <!--  </div> -->
    </div>
	</div>
</template>

<script>
import Vue from 'vue'

var VueScrollTo = require('vue-scrollto');


Vue.use(VueScrollTo, {
     duration: 500,
     easing: "ease",
     offset: -80,
     force: true,
     cancelable: false,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
 })
export default {

  name: 'Nav',
  props:['lang'],
  data () {
    return {
    	items: [
    		/*{key:0,id:'portada',    link:'#portada',text:{'ca':'Inici','en':'Home','es':'Inicio'},		class:'nav-item',active:true},*/
    		{key:1,id:'publicacion',link:'#publicacion',text:{'ca':'Publicació','en':'Publication','es':'Publicación'},	class:'nav-item',active:false},
        {key:2,id:'apoyos', link:'#apoyos', text:{'ca':'Xarxa de suport','en':'Support Network', 'es':'Red de apoyo'}, class:'nav-item', active:false},
    		{key:3,id:'contacto',   link:'#contacto',text:{'ca':'Contacte','en':'Contact','es':'Contacto'},		class:'nav-item',active:false},
        {key:4,id:'difusion',   link:'#difusion',text:{'ca':'Difusió','en':'Outreach','es':'Difusión'},   class:'nav-item',active:false},
        
    	],
      isScrolling:false,
      idiomas:[
        {lang:'es', text:'Es'},
        {lang:'ca', text:'Ca'},
        {lang:'en', text:'En'},

      ],
      dropphone:false,
    }
  },
  mounted(){
    //window.addEventListener('scroll', this.check_if_in_view);
    // Setup isScrolling variable
   window.addEventListener('scroll', this.alscroll,false);
  },
  methods:{
  	activar(key){
  		var select = this.items.find(item=>item.key==key)
  		if(!select.active){
  			this.items.forEach(item=>{
  				item.active=false
  			})	
  			select.active = true;
  		}
    },
    alscroll(event){
      window.clearTimeout( this.isScrolling );
      let _this = this
      // Set a timeout to run after scrolling ends
      this.isScrolling = setTimeout(function() {
        _this.check_if_in_view();
      }, 66);
    },
    check_if_in_view(){
      var windowHeight = window.innerHeight;
      var windowTop = window.scrollY;
      var windowBottom = windowHeight + windowTop;
      var windowMiddle = windowBottom - (windowHeight/2)
      var components = document.querySelectorAll('.component');
      components.forEach(comp=>{
        var compHeight = comp.offsetHeight;
        var compTop = comp.offsetTop;
        var compBottom = compHeight + compTop;
        if(compTop <= windowMiddle && compBottom> windowMiddle){
          this.items.forEach(item=>{
            item.active=false;
          })
          this.items.find(item=>item.id==comp.id).active=true;
        }
        
      })
      
    },
    changeLang(lang){
      this.lang=lang;
       window.location.pathname=`/${lang}`
    },
    showDrop(){
      this.dropphone = true;
    }
  },
  watch: {
    $route(to, from) {
      this.dropphone=false;
    }
  }
}
</script>

<style lang="scss">

</style>