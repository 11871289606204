<template>
	<div id="publicacion" class="component">
		
		<div class="botones">
            <!-- <template v-if="lang!='en'"> -->
			     <a class="boton" :href="'/book/pas-e_'+lang+'.html'" target="_blank"><img src="../assets/ver.png"><transition name="fadein"><span class="tooltip" >{{ver[lang]}}</span></transition></a>
            <!-- </template> -->
           <!--  <template v-if="lang=='en'">
                <button class="boton" @click="showNoDisp"><img src="../assets/ver.png"><transition name="fadein"><span class="tooltip" >{{ver[lang]}}</span></transition></button>
            </template> -->
			<!-- <template v-if="lang!='en'"> -->
                <button class="boton" @click="showModal()"><img src="../assets/descargar.png"><transition name="fadein"><span class="tooltip">{{descargar[lang]}}</span></transition></button>
            <!-- </template> -->
            <!-- <template v-if="lang=='en'">
                <button class="boton" @click="showNoDisp()"><img src="../assets/descargar.png"><transition name="fadein"><span class="tooltip">{{descargar[lang]}}</span></transition></button>
            </template> -->
		</div>
		<div class="intro">
			<div class="col">
				<h3 v-html="titulo[lang]"></h3>
				<p>{{p1[lang]}}</p>
                <p>{{p2[lang]}}</p>
			</div>
			<div class="col">
				<p v-html="p3[lang]"></p>
                <p v-html="p4[lang]"></p>
                <p v-html="p5[lang]"></p>
			</div>
		</div>
		<transition name="fadein">
            <div v-if="modal" class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div v-if="!enviado">
                            <h4>{{formDown[lang]}}</h4>
                            <form class="vue-form" @submit.prevent="submit">
                                <div class="error-message">
                                    <p class="errorp" v-if="faltan">{{faltantext[lang]}}</p>
                                    <p class="errorp" v-if="nomail">{{nomailtext[lang]}}</p>
                                </div>
                                <div class="fieldset">
                                    <div class="grup">
                                        <label class="label" for="nombre">{{labels.nombre[lang]}} *</label>
                                        <input type="text" name="nombre" id="nombre" required="" v-model="nombre">
                                    </div>
                                    <div class="grup">
                                        <label class="label" for="apellidos">{{labels.apellidos[lang]}} *</label>
                                        <input type="text" name="apellidos" id="apellidos" required="" v-model="apellidos">
                                    </div>
                                    <div class="grup">
                                        <label class="label" for="profesion">{{labels.profesion[lang]}} </label>
                                        <input type="text" name="profesion" id="profesion" v-model="profesion">
                                    </div>
                                    <div class="grup">
                                        <label class="label" for="empresa">{{labels.empresa[lang]}} </label>
                                        <input type="text" name="empresa" id="empresa" v-model="empresa">
                                    </div>
                                    <div class="grup">
                                        <label class="label" for="email">{{labels.email[lang]}} *</label>
                                        <input type="email" name="email" id="email" required="" :class="{ email , error: !email.valid }" v-model="email.value">
                                    </div>
                                    <div class="grup politica">
                                        <input type="checkbox" name="politica" v-model="acepto">
                                        <label class="label" for="politica">{{labels.acepto[lang]}}<a :href="'politica_'+lang+'.html'" target="_blank">{{labels.politica[lang]}} </a></label>
                                    </div>
                                    <button class="submit" type="submit" @click.prevent="sendEmail(nombre,email)">
                                        <font-awesome-icon icon="paper-plane" />
                                        <transition name="fadein"><span class="tooltip" >{{labels.enviar[lang]}}</span></transition>
                                    </button>
                                    <button class="submit cancelar" @click.prevent="cancelar()">
                                        <font-awesome-icon icon="times" />
                                        <transition name="fadein"><span class="tooltip" >{{labels.cancelar[lang]}}</span></transition>
                                    </button>
                                </div>
                                
                            </form>
                        </div>
                        <div v-if="enviado">
                            <p v-html="enviadotext[lang]"></p>
                            <button class="submit cancelar" @click.prevent="cancelar()">
                                <font-awesome-icon icon="times" />
                                <transition name="fadein"><span class="tooltip" >{{labels.cancelar[lang]}}</span></transition>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fadein">
            <div v-if="noingles" class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <p>PAS-E Building Passport Spain will be available from March 12.</p>
                      
                            
                            <button class="submit cancelar" @click.prevent="hideNoDisp()">
                                <font-awesome-icon icon="times" />
                                <transition name="fadein"></transition>
                            </button>
                        
                    </div>
                </div>
            </div>
        </transition>	
	</div>
</template>

<script>
import Axios from 'axios'
import VueAxios from 'vue-axios'

export default {

  name: 'Publicacion',
  props:['lang'],
  data () {
    return {
    	    nombre: "",
            apellidos:'',
            profesion:'',
            empresa:'',
            email: {
                value: "",
                valid: true
            },
            faltantext:{
                'en':'Please. fill all the required fields.',
                'es':'Por favor, rellena todos los campos obligatorios.',
                'ca':'Si us plau, omple tots els camps obligatoris.'
            },
            enviadotext:{
                'en':'Thank you very much for your request. <br> We have sent the publication to the email address you provided (check your Spam folder). If you do not receive it correctly, contact us.',
                'es':'Muchas gracias por tu petición.<br> Hemos enviado la publicación a la dirección de correo electrónico que nos has facilitado (revisa tu carpeta de "Spam" o "Correo no deseado"). Si no la recibes correctamente, ponte en contacto con nosotros.',
                'ca':'Moltes gràcies per la teva petició. <br> Hem enviat la publicació a l\'adreça de correu electrònic que ens has facilitat(revisa la teva carpeta de "Spam" o "Correu brossa"). Si no la reps correctament, posa\'t en contacte amb nosaltres.'
            },
            submitted: false,
            faltan:false,
            nomail:false,
            enviado:false,
    		modal:false,
            acepto:false,
            noingles:false,
    		//url:require('../assets/PAS-E_Pasaporte_del_edificio.pdf'),
            url:'/book/pas-e.html',
    		ver:{'en':'','ca':'Veure la publicació','es':'Ver la publicación'},
    		descargar:{'en':'','ca':'Descarregar la publicació','es':'Descargar la publicación'},
    		titulo:{
    			'en':'PAS-E. A proposal for the <span>housing sector</span>',
    			'es':'PAS-E. Una propuesta para el sector <span>de la vivienda</span>',
    			'ca':'PAS-E. Una proposta pel sector <span>de l\'habitatge</span>'
    		},
    		p1:{
    			'en':'We are beginning a new decade that will be decisive for the future of our society and it is clear that we are not moving fast enough; our chances to address the climate emergency are running out.',
    			'es':'Iniciamos una nueva década decisiva para el futuro de nuestra sociedad y resulta evidente que no nos movemos suficientemente rápido; nuestras posibilidades de abordar la emergencia climática se están acabando.',
    			'ca':'Iniciem una nova dècada decisiva pel futur de la nostra societat i resulta evident que no ens movem prou ràpid; les nostres possibilitats d\'abordar l\'emergència climàtica s\'estan acabant.'
    		},
    		p2:{
    			'en':'The European commitment in the form of the Green Deal, for a decarbonised Europe, is a lever that can promote the achievement of the second strategic goal of the Spanish Urban Agenda: urban regeneration. Activating the renovation sector is key, but it is certainly up to us to develop the necessary measures and instruments to accelerate transition.',
    			'es':'La apuesta europea en forma de Green Deal, por una Europa descarbonizada, es una palanca que puede impulsar la consecución del segundo objetivo estratégico de la Agenda Urbana Española: la regeneración urbana. Activar el sector de la rehabilitación es clave, pero sin duda depende de nosotros desarrollar las medidas e instrumentos necesarios para acelerar la transición.',
    			'ca':'L\'aposta europea en forma de Green Deal, per una Europa descarbonitzada, és una palanca que pot impulsar l\'assoliment del segon objectiu estratègic de l\'Agenda Urbana Española: la regeneració urbana. Activar el sector de la rehabilitació és clau, però sens dubte depèn de nosaltres desenvolupar les mesures i instruments necessaris per accelerar la transició.'
    		},
    		p3:{
    			'en':'This was the impetus behind the idea to create and publish a proposal for a new instrument launched by the Directive (EU) 2018/844: <span>the <strong>PAS-E</span> · <span>Building passport.</strong></span>',
    			'es':'De esta constatación nace la voluntad de definir una propuesta para España del nuevo instrumento lanzado por la Directiva (UE) 2018/844: <span>el <strong>PAS-E</span> ·<span> Pasaporte del edificio</strong></span>.',
    			'ca':'D\'aquesta constatació neix la voluntat de definir una proposta per Espanya del nou instrument llançat per la Directiva (UE) 2018/844: <span>el <strong>PAS-E</span> · <span>Passaport de l\'edifici</strong></span>.'
    		},
    		p4:{
    			'en':'The intention is to start a national debate on the opportunity to get on the European train and to decide how it should be defined and implemented in Spain.',
    			'es':'Con la intención de abrir un debate a nivel nacional sobre la oportunidad que significa subirse al tren europeo y sobre cómo debería ser definido e implementado en España.',
    			'ca':'Amb la intenció d\'obrir un debat a nivell estatal sobre l\'oportunitat que significa pujar al tren europeu i sobre com hauria de ser definit i implementat a Espanya.'
    		},
    		p5:{
    			'en':'It is therefore aimed at all stakeholders in the building sector, from public administration and industry to users and owners of the homes themselves.',
    			'es':'Por ello está dirigido a todos los agentes del sector de la edificación, desde la administración y la industria, hasta las propias personas usuarias y propietarias de las viviendas.',
    			'ca':'Per això està dirigit a tots els agents de el sector de l\'edificació, des de l\'administració i la indústria, fins a les pròpies persones usuàries i propietàries dels habitatges.'
    		},
            formDown:{
                'en':'Enter your name and email to receive the publication, Thank you!',
                'es':'Introduce tu nombre y correo electrónico para recibir la publicación, ¡Gracias!',
                'ca':'Introdueix el teu nom i correu electrònic per a rebre la publicació, Gràcies!'
            },
            labels:{
                nombre:{'en':'Name','es':'Nombre','ca':'Nom'},
                apellidos:{'en':'Last name','es':'Apellidos','ca':'Cognoms'},
                profesion:{'en':'Degree, profession or position','es':'Titulación, profesión o cargo','ca':'Titulació, profesió o càrrec'},
                empresa:{'en':'Institution or company','es':'Institución o empresa','ca':'Institució o empresa'},
                email:{'en':'E-mail','es':'Correo electrónico','ca':'Correu electrònic'},
                enviar:{'en':'Send','es':'Enviar','ca':'Enviar'},
                cancelar:{'en':'Cancel','es':'Cancelar','ca':'Cancel·lar'},
                acepto:{'en':'I accept the ','es': 'Acepto la ', 'ca':'Accepto la '},
                politica:{'en':'Privacy Policy', 'es':'Política de privacidad','ca':'Política de privacitat'}
            },
            nomailtext:{
                'en':'The email address is not valid',
                'es':'La dirección de correo electrónico no es válida.',
                'ca':'La direcció de correu electrònic no és vàlida'
            }
    		
    	
    }
  },
  methods: {
  	downloadItem ( url) {
    	Axios.get(url, { responseType: 'blob' })
      	.then(response => {
        	const blob = new Blob([response.data], { type: 'application/pdf' })
        	const link = document.createElement('a')
        	link.href = URL.createObjectURL(blob)
        	link.download = 'PAS-E.Pasaporte_del_edificio.pdf'
        	link.click()
        	URL.revokeObjectURL(link.href)
      	}).catch(console.error)
    },
    showModal(){
        this.modal = true;
        document.querySelector('body').style.overflow = 'hidden'
    },
    sendEmail() {
        var correo = this.email.value
        var nombre = this.nombre;
        var acepto = this.acepto;
        var apellidos = this.apellidos
        if(correo==''||nombre==''||apellidos==''||acepto==false){
            this.faltan = true;
        }
        else {
            function validateEmail(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            }
            this.faltan = false;
            var qs = require('qs');
            if(validateEmail(this.email.value)){
                this.nomail=false;
                Axios({
                    method: 'POST',
                    url: `http://ciclica.eu/descargaPase.php`,
                    data:qs.stringify({ 
                        'email': this.email.value,
                        'nombre': this.nombre,
                        'apellidos':this.apellidos,
                        'profesion':this.profesion,
                        'empresa':this.empresa,
                        'idioma':this.lang
                    }),
                    headers:{}
                })
                .then(response => {
                    console.log(response)
                    this.nombre='';
                    this.apellidos = '';
                    this.profesion = '';
                    this.empresa = '';
                    this.email.value='';
                    this.enviado = true;
                    this.acepto = false;
                    setTimeout(function(){ this.modal=false }, 3000);
                })
                /*.then(response => this.callback(response.data))*/
                .catch(response => console.log('submit error: '+ response))
                /*event.submmit()*/
            } else {
                this.nomail=true;
            }
            
        }
    },
    cancelar() {
        this.modal = false;
        this.enviado = false;
        document.querySelector('body').style.overflow = 'auto'
    },
    showNoDisp(){
        this.noingles = true
    },
    hideNoDisp(){
        this.noingles = false
    }
  },
  mounted(){
    var heightInit= window.innerHeight;
    var padding = innerHeight/4;
    var publicacion = document.querySelector('#publicacion');
    if(publicacion.offsetHeight<=heightInit){
         document.querySelector('#publicacion').style.height=`${heightInit}px`;
    }
  }
}

</script>

<style lang="css" scoped>
</style>