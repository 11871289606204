<template>
	<transition name="fadein">
		<div class="cookies" v-if="cookieson">
			<p>{{p[lang]}}</p>
			<button @click="acepto()" class="submit">
				<font-awesome-icon icon="check" />
                <transition name="fadein"><span class="tooltip" >{{but1[lang]}}</span></transition>
            </button>
			<a class="submit" :href="'/politica_'+lang+'.html#cookies'" target="_blank">
				<font-awesome-icon icon="info" />
                <transition name="fadein"><span class="tooltip" >{{but2[lang]}}</span></transition>
            </a>
		</div>
	</transition>
</template>

<script>

export default {
  props:['lang'],
  name: 'Cokkies',

  data () {
    return {
    	p:{
    		'en':'We use cookies to improve the user experience',
    		'es':'Usamos cookies para mejorar la experiencia de usuario',
    		'ca':'Fem servir cookies per millorar l\'experiència d\'usuari'
    	},
    	but1:{
    		'en':'Accept',
    		'es':'Aceptar',
    		'ca':'Acceptar'
    	},
    	but2:{
    		'en':'More information',
    		'es':'Más información',
    		'ca':'Més informació'
    	},
    	cookieson:false,
    }
  },
  mounted(){
  	var _this = this
    console.log(document.cookie.indexOf('authcookies'))
    if(document.cookie.indexOf('authcookies')<0){
      setTimeout(function(){
        _this.cookieson = true;
      }, 2000)
    }
  	
  },
  methods: {
  	acepto(){
  		this.cookieson = false;
      function setcookie(cookieName,cookieValue) {
            var today = new Date();
            var expire = new Date();
            expire.setTime(today.getTime() + 3600000*24*3);
            document.cookie = cookieName+"="+encodeURI(cookieValue) + ";expires="+expire.toGMTString();
        }
        setcookie('authcookies',true)
  	}
  }
}
</script>

<style lang="css" scoped>
</style>