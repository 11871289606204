import Vue from 'vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
//import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'
//library.add(faFontAwesome)
import {faEye, faFileDownload, faPaperPlane, faTimes, faCheck, faInfo, faList, faPlus, faGlobe } from '@fortawesome/free-solid-svg-icons'
import {faTwitterSquare, faLinkedin} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import router from './router'

library.add(faEye, faFileDownload, faPaperPlane, faTimes, faCheck, faInfo, faList, faPlus, faGlobe, faTwitterSquare,faLinkedin);
Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
