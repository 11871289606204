<template>
  <div id="app">
   
    <Portada :lang="lang"/>
    
    
      <Nav :lang="lang"/>
      <Publicacion :lang="lang"/>
      <Apoyos :lang="lang"/>
      <Contacto :lang="lang"/>
      <Difusion :lang="lang"/>
      <Cookies :lang="lang"/>
      <div class="pie"> 
        <span v-html="frasepie[lang]"></span>
        <div class="logospie"><a href="https://ciclica.eu" target="_blank"><img src="./assets/ciclicaW.png"></a> <a href="https://gbce.es" target="_blank"><img src="./assets/gbceW.png"></a></div>
        <span v-html="frasepie2[lang]"></span>
        <div class="logospie"><a href="https://aracoop.coop" target="_blank"><img src="./assets/aracoop.png"></a><a href="https://treballiaferssocials.gencat.cat" target="_blank"><img src="./assets/generalitat.png"></a><a href="http://www.mitramiss.gob.es/" target="_blank"><img src="./assets/empleo.png"></a></div> 
      </div>
    
  </div>
</template>

<script>
import Nav from './components/Nav'
import Portada from './components/Portada'
import Publicacion from './components/Publicacion'
import Contacto from './components/Contacto'
import Apoyos from './components/Apoyos'
import Cookies from './components/Cookies'
import Difusion from './components/Difusion'
export default {
  name: 'app',
  data () {
    return {
      lang:'es',
     
      clave:'',
      incorrecta:false,
      frasepie:{
        'en':'PAS-E. Building passport is an initiative promoted by <a href="http://ciclica.eu" target="_blank">Cíclica [space · community · ecology]</a> and <a href="http://gbce.es" target="_blank">Green Building Council España (GBCe)</a>. 2020',
        'es':'PAS-E. Pasaporte del edificio es una iniciativa impulsada por <a href="http://ciclica.eu" target="_blank">Cíclica [space · community · ecology]</a> y <a href="http://gbce.es" target="_blank">Green Building Council España (GBCe)</a>. 2020',
        'ca':'PAS-E. Passaport de l\'edifici és una iniciativa impulsada per <a href="http://ciclica.eu" target="_blank">Cíclica [space · community · ecology]</a> i <a href="http://gbce.es" target="_blank">Green Building Council Espanya (GBCe)</a>. 2020'
      },
      frasepie2:{
        'es':'Publicación realizada en el marco de la convocatoria de subvenciones de Projectes Singulars 2018, promovida por el Departament de Treball, Afers Socials i Famílies de la Generalitat de Catalunya y financiada por el Ministerio de Trabajo, Migraciones y Seguridad Social del Gobierno de España.',
        'en':'Publication made within the framework of the Projectes Singulars 2018 grant call, promoted by the Department of Labor, Social Affairs and Families of the Generalitat de Catalunya and funded by the Ministry of Labor, Migration and Social Security of the Government of Spain.',
        'ca':'Publicació realitzada en el marc de la convocatòria de subvencions de Projectes Singulars 2018, promoguda pel Departament de Treball, Afers socials i Famílies de la Generalitat de Catalunya i finançada pel Ministeri de Treball, Migracions i Seguretat Social de el Govern d\'Espanya.'
      },
      textoincorrecta:{
        'es':'La clave introducida no es la correcta',
        'en':'The password entered is not correct',
        'ca':'La clau introduïda no és la correcta'
      },
      textoclave: {
        'es':'Introduce la clave de acceso',
        'en':'Enter the password',
        'ca':'Introdueix la clau d\'accés'
      }
    }
  },
  components:{Nav,Portada,Publicacion,Contacto,Apoyos,Cookies,Difusion},
  mounted(){
    if(this.$route.matched.length!=0){
      this.lang=this.$route.matched[0].props.default.lang;
    }
    

   
    function ajustarHeight(){
      var heightInit= window.innerHeight;
      document.querySelector('#portada').style.height=`${heightInit}px`;
    }
    ajustarHeight();

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      var tooltips = document.querySelectorAll('.tool');
      tooltips.forEach(tooltip=>tooltip.style.opacity='1')
    }
    window.onresize = function(){
      ajustarHeight();
      var components = document.querySelectorAll('.component');
      for(var x = 0;x<components.length;x++){
        var comp = component[x];
        var compHeight = comp.offsetHeight;
        var compTop = comp.offsetTop;
        var compBottom = compHeight + compTop;
        var comp2 = component[x];
        var compTop2 = comp.offsetTop;
        if(compBottom>compTop2){
          comp[x].style.height = 'fit-content';
        }
      }
    }
  },
  methods:{
    claveAcceso(){
      if(this.clave == 'pase2020'){
        this.acceso = true;
        this.incorrecta = false;
      } else {
        this.incorrecta = true;
      }
    }
  },
  watch: {
    $route(to, from) {
      this.lang=to.matched[0].props.default.lang;
    }
  }
}
</script>

<style lang="scss">
$color1:#148979;
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i&display=swap');
*{
  box-sizing: border-box;
}
body{
  margin:0px;
  font-family:'Roboto Condensed', sans-serif;
  color:#fff;
  background: url(assets/fondod.jpg) no-repeat top right fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
}
form {
  
  .error-message{
    
    
  }
}
.errorp  {
      padding:5px;
      border:1px solid;
    }
.clave{
  position:fixed;
  background: #fff;
  color: $color1;
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:10px;
  right:0;
  top:0;
  .submit {
    margin:0px 10px;
    background:#fff;
    color:$color1;
    &:hover{
      background: $color1;
      color:#fff;
    }
  }
  .grup {
    display:flex;
    align-items:center;
    label {
      margin:0 10px;
    }
    input {
      margin:0 10px;
    }
  }
  
}
.component{
  min-height:550px !important;
  padding:80px 60px;
  @media(max-width:750px){
    padding:80px 30px;
  }
}
.textosuport {
  text-align:justify;
  font-size:13px;
  a{
    color:#fff;
    text-decoration:none;
    &:hover {
      color:#ccc;
    }
  }
}
input:required {
    box-shadow: none;
}
.politica {
    flex-direction:row !important;
    align-items:center;
    margin-top:30px;
    a {
      color:#fff;
      text-decoration:none;
      &:hover{
        color:#ddd;
      }
    }
    label {
      margin-bottom:0px !important;
      margin-top:0px !important;
    }
}
.tooltip{
  font-family:'Roboto Condensed',sans-serif;
}
.modal-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000000;

  background-color: rgba(10,69,61,.9);
  display:inline-flex;
  justify-content:center;
  align-items:center;
  top:0;
  right:0;
  overflow-y:scroll;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  margin:auto;
  padding-bottom:40px;
}

.modal-container {
  max-width: 650px;
  width:100%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: transparent;
  border-radius: 5px;
  //box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  
  color:#fff;
  .grup {
    display:flex;
    flex-direction:column;
    margin-bottom: 15px;
    label{
      margin-bottom: 5px;
    }
    input {
      height:20px;
    }
  }
  
  }
  .tooltip{
    top:auto !important;

  }
  .submit {
    float:left;
  }
  .cancelar {
    float:right;
    .tooltip {
     right: 55px !important;
    }
  }

#app {
 max-width:1200px;
 margin:auto;
}
.logos {
  flex: 50%;
      flex: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    a {
      margin:5px;
    }
  img {
    max-width: 170px;
    max-height:120px;
  }
}
#nav {
    background:#fff;
    width:100%;
    height:50px;
    display:flex;
    align-items:flex-end;
    position:fixed;
    top:0px;
    z-index:20;
    right:0px;
    justify-content:space-between;
    .nav1{
      display:flex;
      align-items:center;
      margin-left:15px;
      @media(max-width:750px){
        align-items:center;
        margin-left:0px;
      }

    }
    .logo{
      display:flex;
      margin-left:5px;
      a {
        display:flex;
      }
      img{
        max-height:50px;
      }
    }
  .menu {
    
    display:flex;
    margin-left:20px;
    align-items:center;
    @media(max-width:750px){
      margin-left:0px;
    }
    a {
      text-decoration: none;
      color:#000;
      margin:0px 30px;
      display:flex;
      height:20px;
      position:relative;
      cursor:pointer;
      align-items:center;
      @media(max-width:750px){
        margin:0px 10px 0px 20px;
        align-items:center;
      }

      svg {
        position:absolute;
        left:-18px;

      }
      @media(max-width:480px){
        font-size:.8em;
        margin:0px 5px 0px 15px;
        svg {
          width:10px;
          height:10px;
          left:-13px;
        }
      }
    }
  }

  .menulang {
        display: flex;
    flex-direction: row;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
    height: 100%;
    .botonlang{
      border:0px;
      background:transparent;
      text-transform: uppercase;
      cursor: pointer;
      text-decoration:none;
      margin:0px 5px;
      color:#000;
      &:visited {
        color:#000;
      }
      &.active {
        color: $color1;
        font-weight: bold;
      }
    }
  }
  .menulang.phone{
    display:none !important;
    @media(max-width:750px){
      display: flex !important;
      position: absolute;
      background: #148979;
      height: 30px;
      width: 90px;
      right: 0px;
      bottom: -30px;
      padding: 0px;

    }
    .actual {
      color:#fff;
      text-transform: uppercase;
      cursor:pointer;
      font-weight: bold;
    }
     a.active {
        color:#fff;
      }
    .drop {
      display:flex;
      flex-direction:row;
     
      background: $color1;
      a{
        margin:5px 0px;
        padding:5px 10px;
      }
      a.active {
        color:#fff;
      }
    }
  }
  .menulang.pc{
    
    @media(max-width:750px){
      display:none !important;
    }
  }
}
.bajo {
  padding:0px !important;
  .boton {
  justify-content:flex-start !important;
  padding:0px 20px !important;
  }
}
#portada {
  padding:90px 0px;
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  align-items:center;
  .logo{
    padding: 20px 60px;
    img{
      max-width:500px;
      width:100%;
    }
    h1 {
      color: #fff;
      font-size:22px;
      line-break:strict;
      span{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      @media(max-width:630px){
        font-size:21px;
      }
      @media(max-width:610px){
        font-size:20px;
      }
      @media(max-width:585px){
        font-size:19px;
      }
      @media(max-width:565px){
        font-size:18px;
      }

    }
  }
  .impulsa {
    padding:20px 60px;
    max-width:500px;
    h2{
      color:#fff;
    }
    img {
      margin-right: 30px;
      width:40%;
      display:inherit;
      @media(max-width:750px){
        margin-right:20px;
      }
    }
  }
}
#publicacion{
  display:flex;
  
  //align-items:center;
  justify-content:center;
  flex-direction:column;
  position:relative;
  max-width:1500px;
 
  
  @media(max-width:750px){
    padding: 20px 30px;
  }
  .contenido{
    display:flex;
    flex-direction:column;
    //align-items:center;
    
  }
  
  .title {
    position:absolute;
    top:100px;
    border-bottom: 1px solid;
    width: calc(100% - 120px);
  }
  
  .tooltip {
    position: absolute;
    top: -25px !important;
    color: #fff;
    font-size: 18px;
    width:max-content;
    opacity:0;
    transition:all 0.3s ease;
  }
  .modal-mask {
    .tooltip {
      top:auto !important;
    }
  }
  
}
.contador {
  background:url('./assets/fondoicono.png');
  width:150px;
  height:150px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-size: 2em;
  background-size:contain;
}
.intro {
    text-align:justify;
    margin-bottom: 30px;
    line-height: 1.5;
    display:flex;
    @media(max-width:750px){
      flex-direction:column;
    }
    .col{
      flex:50%;
      padding: 0px 20px;
      position:relative;
      span{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      h3 {
        position:absolute;
        top: -60px;
        text-align:left;
        span{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .botones {
    padding: 0px 20px;
    display:flex;
    //justify-content: center;
    flex-direction:row;
    align-items:center;
    margin-bottom:100px;

    @media(max-width:750px){
      .boton {
       img{
        width:90%;
        max-width:150px;
       }
      }
      .tooltip {
        font-size:15px;
      }
    }
    .boton{
     
      display:flex;
      align-items:center;
      justify-content:center;
      font-size:2em;
      color:#fff;
      position:relative;
      transition:all 0.3s ease;
      flex:50%;
      background: transparent;
      border: 0px;
      cursor:pointer;
      padding:0px;
      &:hover{
       
      }
      &:hover>.tooltip{
        opacity:1 !important;
      }
      &:focus{
        border:0px;
        outline: -webkit-focus-ring-color auto 0px;
      }
      img {
        max-width:150px;

      }
    }
  }
.submit {
     width: 40px;
      height:40px;
      background:$color1;
      display:flex;
      align-items:center;
      justify-content:center;
      font-size:1.5em;
      color:#fff;
      position:relative;
      transition:all 0.3s ease;
      margin-top:23px;
      border:0px;
      .tooltip {
        position: absolute;
        right: -55px;
        color: #fff;
        font-size: 18px;
        width:max-content;
        opacity:0;
        transition:all 0.3s ease;
      }
      &:hover{
        background:#fff;
        color:$color1;
      }
      &:hover>.tooltip{
        opacity:1 !important;
      }
    }
#contacto {
  display:flex;
  
  //align-items:center;
  justify-content:center;
  flex-direction:column;
  position:relative;
  max-width:1500px;
  @media(max-width:750px){
    padding:20px 50px;
  }
  .contenido{
    display:flex;
    flex-direction:column;
    //align-items:center;
  }
  .infocont{
    font-size:1.2em;
    a{
      text-decoration:none;
      color:#fff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .title {
    position:absolute;
    top:40px;
    border-bottom: 1px solid;
    width: calc(100% - 120px);
  }
  .submit{
    margin-top:7px !important;
  }
  .fieldset {
    border:0px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    position:relative;
    @media(max-width:750px){
      flex-direction:column;
      
    }

    .col {
      display:flex;
      flex-direction:column;
      margin-bottom:10px;
      flex:50%;

      .grup{
        display:flex;
        flex-direction:column;
      }
      input,label {
        max-width:80%;
        @media(max-width: 750px){
          max-width:100%;
        }
      }
      label{
        margin:10px 0px 5px;
      }
      input {
        height:23px;
        border:0px;
      }
      textarea{
        height:100%;
        border:0px;
      }
    }
    
  }
}
.tabbuttons {

  button {
    background:transparent;
    font-family:'Roboto Condensed', sans-serif;
    color: #888;
    border:0px;
    font-size: 17px;
    cursor:pointer;
    margin-right:30px;
    @media(max-width:750px){
      margin-right:5px;
    }
    &.active {
      color:#fff;
      &:before {
       
      }
    }
  }
}
#apoyos {
  display: flex;
 
  justify-content: center;
  flex-direction: column;
  position: relative;
  max-width: 1500px;
  box-sizing:border-box;
  .modal-lista{
    .modal-container {
      max-width:100%;
      .cerrar {
        float:right;
        .tooltip {
         right: 55px !important;
         left:auto !important;
        }
      }
    }
  }
  .formusuport{
    max-width:650px;
    .tabsvg {
      margin-bottom:20px;
    }
    .fieldset{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 30px;
      grid-row-gap: 0px;
      @media(max-width:750px){
         grid-template-columns: repeat(1, 1fr);
      }
      input {
        max-width:initial !important;
      }
    }
    .submit {
      
    }
  }
  .listas{
    display:flex;

    h3 {
      margin-bottom: 5px;
    }
    div{
      flex-basis:50%;
      padding:0 20px;
    }
    .lista {
      list-style-type: none;
      padding-left: 0px;
      height:80vh;
      overflow-y:scroll;
      img {
        max-height:60px;
        max-width:120px;
        margin-bottom: 5px;
        margin-left: 5px;
      }
      a {
        text-decoration:none;
        color: #fff;
        display:flex;
        align-items:center;
        flex-direction:row;
        //justify-content: space-between;
        width: 100%;
        span {
          width: calc(100% - 125px);
          .subt {
            font-size:.8em;
          }
        }

      }
      li {
        margin-bottom: 30px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height:60px;
        align-items: center;
        .profesio{
          font-size:.8em;
        }
         p {
            width:calc(100% - 20px)
          }
        &:before {
          content:'';
          height:10px;
          width:10px;
          margin-right:10px;
          background:$color1;
        }
      }
    }
    @media(max-width:775px){
      flex-direction:column;
      .lista {
        height:auto !important;
        margin-right:-40px;
        overflow-y:inherit !important;
        li {
          height:80px;

        }
      }
    }
  }
  .intro {
    margin-bottom: 0px;
    
  }
  .submit {
      width: 50px !important;
      height: 50px !important;
      font-size: 1em;
      .tooltip{
        left: 55px;
      }
    }
  .formusuport {
    //margin-top: 40px;
  }
  .fieldset {
    border:0px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    position:relative;
    @media(max-width:750px){
      flex-direction:column;
      
    }

    .col {
      display:flex;
      flex-direction:column;
      margin-bottom:10px;
      flex:50%;

      .grup{
        display:flex;
        flex-direction:column;
      }
      input,label {
        max-width:80%;
        font-size:14px;
        @media(max-width: 750px){
          max-width:100%;
        }
      }
      label{
        margin:10px 0px 5px;
      }
      input {
        height:23px;
        border:0px;
      }
      textarea{
        height:100%;
        border:0px;
      }
    }
    
  }
}
.cookies {
  position: fixed;
  background:#07312b;
  width:100%;
  bottom:0;
  padding: 10px 20px;
  display:flex;
  justify-content:center;
  align-items:center;
  right:0;

  .submit{
    margin: 0px 10px;
    @media(max-width:750px){
      width:25px;
      height:25px;
      font-size: 1em;
      margin: 0px 5px;
    }

  }
  button {
    cursor:pointer;
  }
  .tooltip{
    right:auto !important;
    top: -15px !important;
    font-size: 12px;
  }
}
.pie {
  width:100%;
  text-align:center;
  margin-bottom: 15px;
  padding:0px 15px;
  box-sizing:border-box;
  a {
    color:#fff;
    text-decoration: none;
    transition: all 0.2s ease;
    &:hover {
      color:#ccc;
    }
  }
  .logospie {
    display:flex;
    align-items:center;
    justify-content:center;
    margin: 10px 0px;
    img {
      max-width:120px;
      margin:5px;
    }
  }

}
#difusion {
  //display:flex;
  a{
    color:#fff;
    text-decoration:none;
    transition:all 0.3s ease;
    &:hover {
      color: #666;
    }
  }
  .difu {
    margin-bottom: 50px;
    h4 {
      margin-bottom:5px;
    }
  }
  .lista-entidades {
    display:flex;
    .entidad {
      flex-basis:33%;
      @media(max-width:750px){
        flex-basis:50%;
      }
      @media(max-width:480px){
        flex-basis:100%;
      }
      padding-right:20px;
      h5 {
        margin-top: 5px;
        margin-bottom: 8px;
        font-size:.9em;
      }
      a{
        margin-right:5px;
      }
    }
  }
  .press {
    display:flex;
    flex-wrap:wrap;
    list-style:none;
    padding-left:5px;
    li {
      flex-basis: 33%;
      padding:5px 10px;
      @media(max-width:750px){
        flex-basis:50%;
      }
      @media(max-width:480px){
        flex-basis:100%;
      }
      &:before{
        content: "\25A0";  
        color: $color1; 
        font-weight: bold; 
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; 
        margin-left: -1em; 
      }
    }
  }
}
iframe{
  @media(max-width:750px){
    width:500px;
    height:282px;
  }
  @media(max-width:550px){
    width:350px;
    height:197px;
  }
}
.youtube-chapter-wrapper .chapters-wrapper {
  border:0px;
}
#chapters-wrapper-soundtrack {
  flex-direction:column;
  list-style-type: none;
  padding-left:0px;
  margin-left:20px;
  @media(max-width:750px){
    margin-left:5px;
  }
  li {
    background:transparent;
    color:#fff !important;
    border:0px;
    &.current {
      border-bottom: 1px solid #fff;
    }
    .chapter-point {
      color:#fff;
      transition:all 0.3s ease;
      padding:10px 0 10px 10px;
      &:hover {
        color: #666;
      }
      &:before{
        content: "\25A0";  
        color: $color1; 
        font-weight: bold; 
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; 
        margin-left: -1em; 
      }
    }

  }
}
#text-wrapper-soundtrack {
  display:none;
  
}
.youtube-chapter-wrapper.complete {
  .chapters-wrapper {
    height:auto !important;
  }
  @media(max-width:750px){
    .youtube-wrapper {
      flex:3 1 100%;
    }
  }
}
.vimeo-wrapper {
  width:70%;
  max-width:100%;
  margin-bottom: 2em;
  @media(max-width:750px){
    width:100%;
  }
}
.vimeo-container {
  position:relative;
  padding-bottom:56.25%;
  height:0;
  overflow:hidden;
  iframe {
        position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
