<template>
	<div id="contacto" class="component">
		<div class="contenido">
			<p class="infocont">{{infocont[lang]}}<a :href="'mailto:'+mail[lang]"> {{mail[lang]}}</a></p>
			<form class="vue-form" @submit.prevent="submit">

			    <div class="error-message">
			      	<p class="errorp" v-if="faltan">{{faltantext[lang]}}</p>
			      	<p class="errorp" v-if="nomail">{{nomailtext[lang]}}</p>
			    </div>

			    <div class="fieldset">
			    	<div class="col">
				      	<div class="grup">
				        	<label class="label" for="nombre">{{labels.nombre[lang]}} *</label>
				        	<input type="text" name="nombre" id="nombre" required="" v-model="nombre">
				      	</div>
				      	<div class="grup">
				        	<label class="label" for="apellidos">{{labels.apellidos[lang]}} *</label>
				        	<input type="text" name="apellidos" id="apellidos" required="" v-model="apellidos">
				      	</div>
				      	<div class="grup">
				        	<label class="label" for="profesion">{{labels.profesion[lang]}} </label>
				        	<input type="text" name="profesion" id="profesion"  v-model="profesion">
				      	</div>
				      	<div class="grup">
				        	<label class="label" for="empresa">{{labels.empresa[lang]}} </label>
				        	<input type="text" name="empresa" id="empresa"  v-model="empresa">
				      	</div>
				      	<div class="grup">
				        	<label class="label" for="email">{{labels.email[lang]}} *</label>
				        	<input type="email" name="email" id="email" required=""
				               :class="{ email , error: !email.valid }"
				               v-model="email.value">
				      	</div>
				      
				    </div>
				    <div class="col">
				      	<div class="grup">
				        	<label class="label" for="mensaje">{{labels.mensaje[lang]}}</label>
				        	<textarea class="message" name="mensaje" id="mensaje" rows="11"
				                  v-model="mensaje.text" 
				                  :maxlength="mensaje.maxlength">
				            </textarea>
				        </div>
				      	<div class="grup politica">
                            <input type="checkbox" name="politica" v-model="acepto">
                            <label class="label" for="politica">{{labels.acepto[lang]}}<a :href="'politica_'+lang+'.html'" target="_blank">{{labels.politica[lang]}} *</a></label>
                        </div>
                        
				       	<!-- <vue-programmatic-invisible-google-recaptcha
						    ref="invisibleRecaptcha1"
						    :sitekey="'6LfUeeAUAAAAAElIVpF274lWopsAOZCs19QR8v_y'"
						    :elementId="'invisibleRecaptcha1'"
						    :badgePosition="'left'"
						    :showBadgeMobile="false"
						    :showBadgeDesktop="true"
						    @recaptcha-callback="recaptchaCallback"
						></vue-programmatic-invisible-google-recaptcha> -->
				        <button class="submit" type="submit" @click.prevent="sendEmail(nombre,email,empresa,mensaje)">
				        	<font-awesome-icon icon="paper-plane" />
				        	<transition name="fadein"><span class="tooltip" >{{labels.enviar[lang]}}</span></transition>
				        </button>
				     
				    </div>
			      
			    </div>
			    <div class="error-message">
			      	<p v-show="enviado">{{enviadotext[lang]}}</p>
			    </div>
			</form>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import Axios from 'axios'
import VueAxios from 'vue-axios'
//import InvisibleRecaptcha from 'vue-invisible-recaptcha';
//import VueProgrammaticInvisibleGoogleRecaptcha from 'vue-programmatic-invisible-google-recaptcha'
//Vue.component('vue-programmatic-invisible-google-recaptcha', VueProgrammaticInvisibleGoogleRecaptcha)
export default {

  name: 'Contacto',
  props:['lang'],
  //components:{"invisible-recaptcha": InvisibleRecaptcha},
 // components:{"vue-programmatic-invisible-google-recaptcha":VueProgrammaticInvisibleGoogleRecaptcha},
  data () {
    return {
    	nombre: "",
    	apellidos:'',
	    email: {
	        value: "",
	        valid: true
	     },
        empresa:'',
        profesion:'',
      	mensaje: {
        	text: '',
        	maxlength: 255
      	},
      	submitted: false,
      	faltan:false,
      	nomail:false,
      	enviado:false,
      	acepto: false,
      	captcha:false,
      	infocont:{
      		'en':'You can contact us through this form or by sending an email to',
      		'es':'Puedes ponerte en contacto con nosotros a través de este formulario o enviando un correo electrónico a',
      		'ca':'Pots posar-te en contacte amb nosaltres a través d\'aquest formulari o enviant un correu electrònic a',
      	},
      	faltantext:{
      		'en':'Please, fill all the required fields.',
      		'es':'Por favor, rellena todos los campos obligatorios.',
      		'ca':'Si us plau, omple tots els camps obligatoris.'
      	},
      	labels:{
      		nombre:{'en':'Name','es':'Nombre','ca':'Nom'},
      		apellidos:{'en':'Last name','es':'Apellidos','ca':'Cognoms'},
            profesion:{'en':'Degree, profession or position','es':'Titulación, profesión o cargo','ca':'Titulació, professió o càrrec'},
            empresa:{'en':'Institution or company','es':'Institución o empresa','ca':'Institució o empresa'},
      		email:{'en':'E-mail','es':'Correo electrónico','ca':'Correu electrònic'},
      		mensaje:{'en':'Message','es':'Mensaje','ca':'Missatge'},
      		enviar:{'en':'Send','es':'Enviar','ca':'Enviar'},
      		acepto:{'en':'I accept the ','es': 'Acepto la ', 'ca':'Accepto la '},
            politica:{'en':'Privacy Policy', 'es':'Política de privacidad','ca':'Política de privacitat'}
      	},
      	enviadotext:{
      		'en':'Your message has been sent correctly, thank you very much!',
      		'es':'Tu mensaje se ha enviado correctamente, ¡muchas gracias!',
      		'ca':'El teu missatge s\'ha enviat correctament, moltes gràcies!'
      	},
      	nomailtext:{
            'en':'The email address is not valid',
            'es':'La dirección de correo electrónico no es válida.',
            'ca':'La direcció de correu electrònic no és vàlida'
        },
        mail:{
        	'en':'info@pas-e.eu',
        	'es':'info@pas-e.es',
        	'ca':'info@pas-e.cat'
        }
    }
  },
  methods: {
 	doSomething(recaptchaToken){console.log(recaptchaToken)},
    sendEmail() {
  		var correo = this.email.value
  		var nombre = this.nombre;
  		var mensaje = this.mensaje;
  		var apellidos = this.apellidos;
  		if(correo==''||nombre==''||apellidos==''||this.acepto==false){
  			this.faltan = true;
  		}
		else {
			function validateEmail(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            }
			this.faltan = false;
	  	    var qs = require('qs');
	  	    if(validateEmail(this.email.value)){
	  	    	this.nomail = false;
	  	    	Axios({
	                  method: 'POST',
	                  url: `http://ciclica.eu/send-contact-pase.php`,
	                  data:qs.stringify({ 
	                  	'email': this.email.value,
	                  	'nombre': this.nombre,
	                  	'apellidos':this.apellidos,
	                  	'profesion':this.profesion,
	                  	'empresa':this.empresa,
	                  	'mensaje':this.mensaje.text,
	                  	'idioma': this.lang
	                  }),
	                  headers:{}
	                })
	                .then(response => {
	                	console.log(response)
	                	this.nombre='';
	                	this.apellidos='';
	                	this.profesion='';
	                	this.email.value='';
	                	this.mensaje='';
	                	this.empresa ='';
	                	this.enviado = true;
	                	this.acepto = false;
	                })
	             
	                
	                .catch(response => console.log('submit error: '+ response))
	                var _this = this;
	                setTimeout(function(){
	                	_this.enviado = false;
	                },6000)
				
	  	    } else {
	  	    	this.nomail = true;
	  	    }
	  		
		}
	}
    
  },
 mounted(){
 	var heightInit= window.innerHeight;
    var padding = innerHeight/4;
    var contacto = document.querySelector('#contacto');
    if(contacto.offsetHeight<=heightInit){
    	 document.querySelector('#contacto').style.height=`${heightInit}px`;
    }
 }
}
</script>

<style lang="css" scoped>
</style>