import Vue from 'vue'
import Router from 'vue-router'
import App from '../App'

Vue.use(Router)

export default new Router({

	//mode: 'history',
	base:'/',
  routes: [
    {
      path: '/es',
      component: App,
      props:{lang:'es'},
      lang:'es'
    },
    {
      path: '/ca',
      component: App,
      props:{lang:'ca'},
      lang:'ca'
    },
    {
      path: '/en',
      component: App,
      props:{lang:'en'}
    },
  ]
})
