export const profesionals = [
    {
        nom:'Albert Cuchí', 
        profesio:{'es':'Dr. Arquitecto. Director de la ETSAV-UPC. Miembro de GTR','en':'Dr. Architect. Director of the ETSAV-UPC. Member of GTR','ca':'Dr. Arquitecte. Director de l\'ETSAV-UPC. Membre de GTR'},
        empresa:{'es':'Escuela de Arquitectura del Vallés (ETSAV-UPC)','en':'Vallès School of Architecture (ETSAV-UPC)','ca':'Escola d\'Arquitectura del Vallès (ETSAV-UPC)'}
    },
    {
        nom:'Jordi Mas',
        profesio:{'es':'Arquitecto. Coordinador técnico del Consorcio de la Vivienda del Área Metropolitana de Barcelona y miembro de GTR','en':'Architect. Technical coordinator of the Housing Consortium of the Barcelona Metropolitan Area and member of GTR','ca':'Arquitecte. Coordinador tècnic de el Consorci de l\'Habitatge de la Àrea Metropolitana de Barcelona i membre de GTR'}, 
        empresa:{'es':'','en':'','ca':''}
    },
    {
        nom:'Núria Pedrals',
        profesio:{'es':'Coordinadora del Observatorio de Barcelona para la Rehabilitación Arquitectónica (OBRA)','en':'Coordinator of the Barcelona Observatory for Architectural Rehabilitation (OBRA)','ca':'Coordinadora de l\'Observatori de Barcelona per a la Rehabilitació Arquitectònica (OBRA)'},
        empresa:{'es':'','en':'','ca':''}
    },
    {
        nom:'Peter Sweatman',
        profesio:{'es':'Presidente de Climate Strategy y miembro de GTR','ca':'President de Climate Strategy i membre de GTR','en':'President of Climate Strategy and member of GTR'},
        empresa:{'es':'','en':'','ca':''}
    },
    {
        nom:'Eva París',
        profesio:{'es':'Jefe de Area del Control del Parque, Ordenación y de I + D + I de la Edificación de la Agencia de la Vivienda de Cataluña','ca':'Cap d’Area del Control del Parc, Ordenació i de R+D+I de l’Edificació de l\'Agència de l\'Habitatge de Catalunya','en':'Head of Area of the Control of the Park, Planning and R&D of the Building of the Housing Agency of Catalonia'}, 
        empresa:{'es':'','en':'','ca':''}
    },
    {
        nom:'Marta Arrufí', 
        profesio:{'es':'Responsable de Sostenibilidad e I + D + I de la Edificación de la Agencia de la Vivienda de Cataluña','en':'Responsible for Sustainability and R&D of the Construction of the Housing Agency of Catalonia','ca':'Responsable de Sostenibilitat i R+D+I de l’Edificació de l\'Agència de l\'Habitatge de Catalunya'}, 
        empresa:{'es':'','en':'','ca':''}
    },
    {
        nom:'Sergio Tirado Herrero', 
        profesio:{'es':'Investigador principal','en':'Principal investigator','ca':'Investigador principal'},
        empresa:{'es':'Instituto de Ciencia y tecnología Ambientales (ICTA), Universitat Autònoma de Barcelona (UAB)','en':'Institute of Environmental Science and Technology (ICTA), Autonomous University of Barcelona (UAB)','ca':'Institut de Ciència i tecnologia Ambientals (ICTA), Universitat Autònoma de Barcelona (UAB)'}
    },
    {
        nom:'Ainhoa Mata', 
        profesio:{'es':'Jefe de Unidad de Edificios','en':'Head of Buildings Unit','ca':'Cap d’Unitat d’Edificis'},
        empresa:{'es':'Instituto Catalán de Energía (ICAEN)','en':'Catalan Institute of Energy (ICAEN)','ca':'Institut Català d\'Energia (ICAEN)'}
    },
    {
        nom:'Lluis Morer', 
        profesio:{'es':'Jefe de Área de eficiencia energética','en':'Head of Energy Efficiency Area','ca':'Cap d\'Àrea d\'eficiència energètica'},
        empresa:{'es':'Instituto Catalán de Energía (ICAEN)','en':'Catalan Institute of Energy (ICAEN)','ca':'Institut Català d\'Energia (ICAEN)'}
    },
    {
        nom:'Jordi Pascual', 
        profesio:{'es':'Físico Doctor e investigador senior','en':'Physicist Doctor and senior researcher','ca':'Físic Doctor i investigador sènior'},
        empresa:{'es':'IREC','en':'IREC','ca':'IREC'}
    },
    {
        nom:'Eloi Juvillà Ballester', 
        profesio:{'es':'Arquitecto','en':'Architect','ca':'Arquitecte'},
        empresa:{'es':'Diputación de Barcelona','en':'Barcelona Provincial Council','ca':'Diputació de Barcelona'}
    },
    {
        nom:'Davide Cannarozzi', 
        profesio:{'es':'CEO','en':'CEO','ca':'CEO'},
        empresa:{'es':'GNE Finance','en':'GNE Finance','ca':'GNE Finance'}
    },
    {
        nom:'Juan Diego Berjón Sánchez', 
        profesio:{'es':'Jefe Servicio','en':'Head of Service','ca':'Cap de servei'}, 
        empresa:{'es':'','en':'','ca':''}
    },
    {
        nom:'Moisès Morató Güell', 
        profesio:{'es':'Ingeniero industrial','en':'Industrial engineer','ca':'Enginyer industrial'},
        empresa:{'es':'TMB','en':'TMB','ca':'TMB'}
    },
    {
        nom:'Cynthia Echave', 
        profesio:{'es':'Dr.Arquitecto experto en Ecología Urbana','en':'Dr. Architect expert in Urban Ecology','ca':'Dr.Arquitecte expert en ecologia urbana'},
        empresa:{'es':'BCNecologia','en':'BCNecologia','ca':'BCNecologia'}
    },
    {
        nom:'Roger Sauquet Llonch', 
        profesio:{'es':'Arquitecto','en':'Architect','ca':'Arquitecte'},
        empresa:{'es':'UPC - ETSAV','en':'UPC - ETSAV','ca':'UPC - ETSAV'}
    },
    {
        nom:'Irene Rodríguez Vara', 
        profesio:{'es':'Arquitecta','en':'Architect','ca':'Arquitecta'},
        empresa:{'es':'Observatorio Metropolitano de la Vivienda de Barcelona (O-HB)','en':'Metropolitan Housing Observatory of Barcelona (O-HB)','ca':'Observatori Metropolità de l\'Habitatge de Barcelona (O-HB) '}
    },
    {
        nom:'Cristina Jiménez Pulido', 
        profesio:{'es':'Arquitecta, investigadora','ca':'Arquitecta, investigadora','en':'Architect, researcher'}, 
        empresa:{'es':'','en':'','ca':''}
    },
    {
        nom:'Darién Germán Roque Montes Ríos', 
        profesio:{'es':'Arquitecto','en':'Architect','ca':'Arquitecte'}, 
        empresa:{'es':'','en':'','ca':''}
    },
    {
        nom:'Francisco Aguilera Cebrián ', 
        profesio:{'es':'Grado en Edificación (Ingeniería de Edificación)','en':'Degree in Building (Building Engineering)','ca':'Grau en Edificació (Enginyeria d\'Edificació)'}, 
        empresa:{'es':'','en':'','ca':''}
    },
    {
        nom:'María González', 
        profesio:{'es':'Arquitecta técnica','en':'Technical architect','ca':'Arquitecta tècnica'}, 
        empresa:{'es':'EficienciaYA.net','en':'EficienciaYA.net','ca':'EficienciaYA.net'}
    },
    {
        nom:'David Molina García', 
        profesio:{'es':'Arquitecto técnico','en':'Technical architect','ca':'Arquitecte tècnic'}, 
        empresa:{'es':'Junta de Andalucía','en':'Junta de Andalucía','ca':'Junta de Andalucía'}
    },
    {
        nom:'Pablo Maroto Milán', 
        profesio:{'es':'Ingeniero de Edificación','en':'Building Engineer','ca':'Enginyer d\'Edificació'}, 
        empresa:{'es':'Knauf GmbH','en':'Knauf GmbH','ca':'Knauf GmbH'}
    },
    {
        nom:'José Fco Zapater', 
        profesio:{'es':'Arquitecto','en':'Architect','ca':'Arquitecte'}, 
        empresa:{'es':'ARG Arquitectos (Arquitectura Rehabilitación y Gestión SL)','en':'ARG Arquitectos (Arquitectura Rehabilitación y Gestión SL)','ca':'ARG Arquitectos (Arquitectura Rehabilitación y Gestión SL)'}
    },
    {
        nom:'Fernando Polo Elías', 
        profesio:{'es':'Arquitecto técnico','en':'Technical architect','ca':'Arquitecte tècnic'},
        empresa:{'es':'Profesional libre','en':'Freelance','ca':'Professional Lliure'}
    },
    {
        nom:'Sonia Valiente Jiménez', 
        profesio:{'es':'Arquitecta técnica','en':'Technical architect','ca':'Arquitecta tècnica'},
        empresa:{'es':'Docente en Formación Profesional. Autónoma del Sector','en':'Teacher in Higher Education. Freelance','ca':'Docent en Formació Professional. Autònoma del sector'}
    },
    {
        nom:'Leticia Ortega Madrigal', 
        profesio:{'es':'Doctora Arquitecta responsable del Área de Formación y Empleo','en':'PhD Architect responsible for the Training and Employment Area','ca':'Doctora Arquitecta responsable de l\'Àrea de Formació i Ocupació'},
        empresa:{'es':'Instituto Valenciano de la Edificación','en':'Valencian Institute of Building','ca':'Institut Valencià de l\'Edificació'}
    },
    {
        nom:'Sergio Pérez Cobos', 
        profesio:{'es':'Arquitecto especialista en Energía en los edificios','en':'Architect specialist in Energy in buildings','ca':'Arquitecte especialista en Energia en els edificis'},
        empresa:{'es':'Área Metropolitana de Barcelona AMB ','en':'Barcelona Metropolitan Area AMB','ca':'Àrea Metropolitana de Barcelona AMB'}
    },
    {
        nom:'Cristina Cabello Matud', 
        profesio:{'es':'Arquitecta','en':'Architect','ca':'Arquitecta'},
        empresa:{'es':'Unizar / Observatorio Ciudad 3R','en':'Unizar / Observatorio Ciudad 3R','ca':'Unizar / Observatorio Ciudad 3R'}
    },
    {
        nom:'María Luisa Fumero Pérez', 
        profesio:{'es':'Ingeniero Civil','en':'Civil engineer','ca':'Enginyer Civil'},
        empresa:{'es':'Ingevalca C.A.','en':'Ingevalca C.A.','ca':'Ingevalca C.A.'}
    },
    {
        nom:'Julián Pérez Navarro', 
        profesio:{'es':'Arquitecto Técnico - Ingeniero Edificación','en':'Technical Architect - Building Engineer','ca':'Arquitecte tècnic - Enginyer Edificació'},
        empresa:{'es':'','en':'','ca':''}
    },
    
    
    {
        nom:'Daniel Serrano Serrat', 
        profesio:{'es':'Arquitecto','en':'Architect','ca':'Arquitecte'},
        empresa:{'es':'Ayuntamiento de Badia del Vallès','en':'City Council of Badia del Vallès','ca':'Ajuntament de Badia del Vallès'}
    },
    {
        nom:'Francesc López Nogués', 
        profesio:{'es':'Arquitecto','en':'Architect','ca':'Arquitecte'},
        empresa:{'es':'Arquitectura connectada','en':'Arquitectura connectada','ca':'Arquitectura connectada'}
    },
    {
        nom:'Marta Bordas Eddy', 
        profesio:{'es':'Dra. Arquitecta, especialista en accesibilidad','en':'Dr. Architect, specialist in accessibility','ca':'Dra. Arquitecta, especialista en accessibilitat'},
        empresa:{'es':'SomHabitat','en':'SomHabitat','ca':'SomHabitat'}
    },
    
    {
        nom:'Arántzazu España Roch', 
        profesio:{'es':'Arquitecta, consultora de estructuras','en':'Architect, structural consultant','ca':'Arquitecta, consultora d’estructures'},
        empresa:{'es':'Indus','en':'Indus','ca':'Indus'}
    },
    {
        nom:'Paul Espinoza Zambrano', 
        profesio:{'es':'Arquitecto y Investigador','en':'Architect and Investigator','ca':'Arquitecte i investigador'},
        empresa:{'es':'Universidad Politécnica de Cataluña','en':'Polytechnic University of Catalonia','ca':'Universitat Politècnica de Catalunya'}
    },
    {
        nom:'Marta Campmany Casas', 
        profesio:{'es':'Arquitecta','en':'Architect','ca':'Arquitecta'},
        empresa:{'es':'Marta Campmany Casas -arquitecte','en':'Marta Campmany Casas -arquitecte','ca':'Marta Campmany Casas -arquitecte'}
    },
    {
        nom:'Cristian López Bueno', 
        profesio:{'es':'Arquitecto','en':'Architect','ca':'Arquitecte'},
        empresa:{'es':'CLB Arquitectura','en':'CLB Arquitectura','ca':'CLB Arquitectura'}
    },
    {
        nom:'Patricia López', 
        profesio:{'es':'Sostenibilidad','en':'Sustainability','ca':'Sostenibilitat'},
        empresa:{'es':'Finsa','en':'Finsa','ca':'Finsa'}
    },
    {
        nom:'Laura García Fernández', 
        profesio:{'es':'Arquitecta','en':'Architect','ca':'Arquitecta'},
        empresa:{'es':'Arktplus Ibiza','en':'Arktplus Ibiza','ca':'Arktplus Ibiza'}
    },
    {
        nom:'Eduardo Martín del Toro', 
        profesio:{'es':'Doctor Arquitecto, investigador','en':'PhD Architect, researcher','ca':'Doctor Arquitecte, investigador'},
        empresa:{'es':'Universidad de las Palmas de Gran Canaria','en':'University of Las Palmas de Gran Canaria','ca':'Universitat de las Palmas de Gran Canaria'}
    },
    {
        nom:'Marcello Avanzini', 
        profesio:{'es':'Ingeniero de energía renovable','en':'Renewable energy engineer','ca':'Enginyer d\'energia renovable'},
        empresa:{'es':'Universidad Politécnica de Cataluña','en':'Polytechnic University of Catalunya','ca':' Universitat Politècnica de Catalunya'}
    },
    {
        nom:'Pedro Cruz-Auñón Briones', 
        profesio:{'es':'Arquitecto','en':'Architect','ca':'Arquitecte'},
        empresa:{'es':'','en':'','ca':''}
    },
    {
        nom:'Raquel Fernández Antoñanzas', 
        profesio:{'es':'Arquitecta','en':'Architect','ca':'Arquitecta'},
        empresa:{'es':'RFA Estudio','en':'RFA Estudio','ca':'RFA Estudio'}
    },
    {
        nom:'Zuzana Prochazkova', 
        profesio:{'es':'Arquitecta responsable de I+D+i','en':'Architect  responsible for R&D&i','ca':'Arquitecta responsable de I+D+i'},
        empresa:{'es':'Pich Architects','en':'Pich Architects','ca':'Pich Architects'}
    },
    {
        nom:'Ramón Muñoz i Jordán', 
        profesio:{'es':'Arquitecto','en':'Architect','ca':'Arquitecte'},
        empresa:{'es':'Ramón Muñoz i Jordán / Escola Sert','en':'Ramón Muñoz i Jordán / Escola Sert','ca':'Ramón Muñoz i Jordán / Escola Sert'}
    },
    {
        nom:'Bernat Navarro Gibert', 
        profesio:{'es':'Arquitecto técnico','en':'Technical architect','ca':'Arquitecte tècnic'}, 
        empresa:{'es':'Navarro Tècnics','en':'Navarro Tècnics','ca':'Navarro Tècnics'}
    },
    {
        nom:'Anna Ibarz Pascual', 
        profesio:{'es':'Arquitecta, especialista en restauración y rehabilitación','en':'Architect, specialist in restoration and rehabilitation','ca':'Arquitecta, especialista en restauració i rehabilitació'}, 
        empresa:{'es':'Navarro Tècnics','en':'Navarro Tècnics','ca':'Navarro Tècnics'}
    },
    {
        nom:'Borja Izaola', 
        profesio:{'es':'Arquitecto','en':'Architect','ca':'Arquitecte'}, 
        empresa:{'es':'Gbce','en':'Gbce','ca':'Gbce'}
    },
    {
        nom:'Elena Miret Establés', 
        profesio:{'es':'Arquitecta','en':'Architect','ca':'Arquitecta'}, 
        empresa:{'es':' Vicinay Miret arquitectos','en':' Vicinay Miret arquitectos','ca':' Vicinay Miret arquitectos'}
    },
    {
        nom:'Yeny Andrea Márquez Echeverría', 
        profesio:{'es':'Arquitecta, consultora en sostenibilidad de la edificación','en':'Architect, building sustainability consultant','ca':"Arquitecta, consultora en sostenibilitat de l'edificació"}, 
        empresa:{'es':'JG INGENIEROS','en':'JG INGENIEROS','ca':'JG INGENIEROS'}
    },
    {
        nom:'Maialen Sagarna Aranburu', 
        profesio:{'es':'Arquitecta, profesora investigadora','en':'Architect, research professor','ca':"Arquitecta, professora investigadora"}, 
        empresa:{'es':'UPV/EHU','en':'UPV/EHU','ca':'UPV/EHU'}
    },
    {
        nom:'Antonia Pacios', 
        profesio:{'es':'Profesora','en':'Professor','ca':"Professora"}, 
        empresa:{'es':'ETSIAE-UPM','en':'ETSIAE-UPM','ca':'ETSIAE-UPM'}
    },
    {
        nom:'Gemma Blanes Fontan', 
        profesio:{'es':'Arquitecta','en':'Architect','ca':'Arquitecta'}, 
        empresa:{'es':' HEM green&balancedArchitecture','en':' HEM green&balancedArchitecture','ca':' HEM green&balancedArchitecture'}
    }
    
    
]