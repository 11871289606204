<template>
	<div id="portada" class="component">
		<div class="logo">
			<img :src="logos[lang]" :alt="titulo[lang]">
			
		</div>
		<div class="impulsa">
			<h2>{{impulsa[lang]}}</h2>
			<a href="http://ciclica.eu" target="_blank"><img src="../assets/ciclicaW.png" alt="Cíclica [space·community·ecology]"></a>
			<a href="https://gbce.es/" target="_blank"><img src="../assets/gbceW.png" alt="gbce"></a>
		</div>
	</div>
</template>

<script>
export default {

  name: 'Portada',
  props:['lang'],
  data () {
    return {
    	titulo:{
    		'es':'PAS-E Pasaporte del edificio. Instrumento para la rehabilitación profunda por pasos',
    		'ca':'PAS-E Passaport de l\'edifici. Instrument per a la rehabilitació profunda per passos',
    		'en':'PAS-E Building Passport Spain. Instrument for steged deep renovation'
    	},
    	impulsa:{
    		'es':'Impulsa',
    		'en':'Promotes',
    		'ca':'Impulsa'
    	},
      logos:{
        'en':require('../assets/pas-e_en.png'),
        'ca':require('../assets/pas-e_ca.png'),
        'es':require('../assets/pas-e_es.png'),
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>