export const institucions= [
    {logo:require('../assets/apoyos/etsav.png'),nom:'Escola d\'Arquitectura del Vallès (ETSAV-UPC)', url:'https://etsav.upc.edu/', tipus:'institucio'},
    {logo:require('../assets/apoyos/coac.png'),nom:'Col·legi d\'Arquitectes de Catalunya', url:'https://www.arquitectes.cat/', tipus:'institucio'},
    {logo:require('../assets/apoyos/five.png'),nom:'Instituto Valenciano de la Edificación', url:'http://five.es/', tipus:'institucion'},
    {logo:require('../assets/apoyos/icaen.png'),nom:'Institut Català d\'Energia (ICAEN)', url:'http://icaen.gencat.cat', tipus:'empresa'},
    {logo:require('../assets/apoyos/users.png'),nom:'Hard-To-Reach Energy Users Annex <span class="subt">(Technology Collaboration Programme by the International Energy Agency)</span>', url:' https://userstcp.org/annex/hard-to-reach-energy-users/', tipus:'empresa'},
    {logo:require('../assets/apoyos/irec.png'),nom:'Institut de Recerca En Energía de Catalunya (IREC)', url:'http://www.irec.cat/', tipus:'empresa'},
    {logo:require('../assets/apoyos/habitat3.png'),nom:'Fundació Hàbitat3', url:'http://www.habitat3.cat/', tipus:'empresa'},
    {logo:require('../assets/apoyos/AISLA.png'),nom:'AISLA, Asociación de Instaladores de Aislamiento', url:'http://www.aisla.org', tipus:'empresa'},
    {logo:require('../assets/apoyos/badia.png'),nom:'Ajuntament de Badia del Vallès', url:'http://www.badiadelvalles.net/', tipus:'institucio'},
    {logo:require('../assets/apoyos/viladecans.png'),nom:'Ajuntament de Viladecans', url:'http://www.viladecans.cat/', tipus:'institucio'},
    {logo:require('../assets/apoyos/masnou.png'),nom:'Ajuntament del Masnou', url:'http://www.elmasnou.cat/', tipus:'institucio'},
    {logo:require('../assets/apoyos/caviar.png'),nom:'Grupo de investigación CAVIAR, UPV-EHU', url:'https://www.ehu.eus/es/web/caviar/home', tipus:'empresa'},
    {logo:require('../assets/apoyos/otago.png'),nom:'Centre for Sustainability, University of Otago', url:'https://www.otago.ac.nz/centre-sustainability/index.html', tipus:'empresa'},
    {logo:require('../assets/apoyos/get.png'),nom:'Grup Energia i Territori - Institut d\'Estudis Catalans', url:'http://www.iec.cat', tipus:'institucio'},
    {logo:require('../assets/apoyos/gne.png'),nom:'Global New Energy Finance SL', url:'http://www.gnefinance.com/', tipus:'empresa'},
    {logo:require('../assets/apoyos/aiguasol.png'),nom:'AIGUASOL SCCL', url:'http://www.aiguasol.coop/', tipus:'empresa'},
    {logo:require('../assets/apoyos/ecoserveis.png'),nom:'Ecoserveis', url:'https://www.ecoserveis.ne', tipus:'institucio'},
    {logo:require('../assets/apoyos/greenovations.png'),nom:'GREENOVATION 203, SL', url:'https://greenovation203.com/', tipus:'empresa'},
    {logo:require('../assets/apoyos/surgeambiental.png'),nom:'Surge Ambiental', url:'www.surgeambiental.com', tipus:'empresa'},
    {logo:require('../assets/apoyos/DSC.png'),nom:'DSC Arquitectura', url:'https://www.dscarquitectura.es', tipus:'empresa'},
    {logo:require('../assets/apoyos/ABEArquitectos.png'),nom:'Anasagasti-Basterra-Ereño, SLP', url:'http://www.anasagastibasterra.com/', tipus:'empresa'},
    {logo:require('../assets/apoyos/ges.png'),nom:'GES Arquitectos y Asociados', url:'www.gesarquitectos.es', tipus:'empresa'},
    {logo:require('../assets/apoyos/dafo.png'),nom:'Dafo Ecoenergias SL', url:'http://dafoecoenergy.net/', tipus:'empresa'},
    {logo:require('../assets/apoyos/aia.png'),nom:'AIA Estudio', url:'http://www.aiaestudio.com/', tipus:'empresa'},
    {logo:require('../assets/apoyos/arg.png'),nom:'ARG Arquitectos', url:'www.arquitectos-arg.com', tipus:'empresa'},
    {logo:require('../assets/apoyos/vaic.png'),nom:'VAIC Mobility', url:'http://www.vaicmobility.com/', tipus:'empresa'},
    {logo:require('../assets/apoyos/ravetllat.png'),nom:'Ravetllat arquitectura', url:'http://ravetllatarquitectura.com/', tipus:'empresa'},
    
    {logo:require('../assets/apoyos/oguti.png'),nom:'Óscar Gutiérrez. Project Management', url:'http://www.ogutipm.com/', tipus:'empresa'},
    
    {logo:require('../assets/apoyos/gf.png'),nom:'Gorina i Farres Arquitectes SLP', url:'http://www.gfarquitectes.com/', tipus:'empresa'},
    {logo:require('../assets/apoyos/asolba.png'),nom:'Asolba SL', url:'http://www.asolba.com/', tipus:'empresa'},
    
    {logo:require('../assets/apoyos/dmdva.png'),nom:'DMDV Arquitectos', url:'https://www.dmdva.com', tipus:'empresa'},
    {logo:require('../assets/apoyos/celobert.png'),nom:'CELOBERT SCCL, Arquitectura, enginyeria i urbanisme', url:'https://www.celobert.coop', tipus:'empresa'},
    {logo:require('../assets/apoyos/ocs.png'),nom:'Ocs Arquitectura and Management ®', url:'https://www.ocsam.es', tipus:'empresa'},
    {logo:require('../assets/apoyos/jazz.png'),nom:'MF & JAZZ ARCHITECTS, S.L.P.', url:'https://www.mfarquitectos.com', tipus:'empresa'},
    {logo:require('../assets/apoyos/deltoro.png'),nom:'Del Toro & Antúnez ARQUITECTOS', url:'http://www.deltoroantunez.com/', tipus:'empresa'},
    {logo:require('../assets/apoyos/anfapa.png'),nom:'ANFAPA', url:'http://www.anfapa.com/', tipus:'empresa'},
    {logo:require('../assets/apoyos/haus.png'),nom:'HAUS Healthy Buildings', url:'https://www.haushealthybuildings.com/', tipus:'empresa'},
    {logo:require('../assets/apoyos/pich.png'),nom:'Pich Architects', url:'http://www.picharchitects.com', tipus:'empresa'},
    {logo:require('../assets/apoyos/tormesh.png'),nom:'Tormesh Solutions', url:'http://www.tormesh.com', tipus:'empresa'},
    {logo:require('../assets/apoyos/mvapm.png'),nom:'MVAPM SL', url:'http://www.mvapm.com', tipus:'empresa'},
    {logo:require('../assets/apoyos/ecodes.png'),nom:'ECODES - Fundación ecología y desarrollo', url:'http://www.ecodes.org', tipus:'institucio'},
    {logo:require('../assets/apoyos/asla.png'),nom:'Asla Green Solutions', url:'http://www.aslagreensolutions.com', tipus:'empresa'},
    {logo:require('../assets/apoyos/brussels.png'),nom:'Brussels Consulting', url:'http://www.brusselsconsulting.org', tipus:'empresa'},
    {logo:require('../assets/apoyos/hm20.png'),nom:'HM20 Partners Consultores de Edificación S.L.', url:'https://www.hm20.es/', tipus:'empresa'},
    {logo:require('../assets/apoyos/rebive.png'),nom:'reBive S Coop MAd', url:'https://www.rebive.com/', tipus:'empresa'},
    {logo:require('../assets/apoyos/greenward.png'),nom:'Greenward Partners', url:'https://www.greenwardpartners.com/', tipus:'empresa'},
    {logo:require('../assets/apoyos/linkark.png'),nom:'LINKARK', url:'https://www.linkark.es/', tipus:'empresa'},
    {logo:require('../assets/apoyos/build.png'),nom:'Build Academy', url:' http://www.buildacademy.com', tipus:'empresa'},
    {logo:require('../assets/apoyos/ietcc.png'),nom:'Instituto Eduardo Torroja de ciencias de la construcción', url:' https://www.ietcc.csic.es/', tipus:'institucio'},
    {logo:require('../assets/apoyos/fenage.png'),nom:'Federacion Nacional de Gestores Energéticos-FENAGE', url:'http://www.fenage.com/', tipus:'institucio'},
    {logo:require('../assets/apoyos/coaa.png'),nom:'Colegio Oficial de Arquitectos de Aragón', url:'http://www.coaaragon.es', tipus:'institucio'},
    {logo:require('../assets/apoyos/predictive.png'),nom:'The Predictive Company', url:'https://thepredictivecompany.com/', tipus:'empresa'},
    {logo:require('../assets/apoyos/comillas.png'),nom:'IIT Comillas', url:'https://www.comillas.edu/catedras-de-investigacion/catedra-de-energia-y-pobreza/', tipus:'empresa'},
    
    
    


]