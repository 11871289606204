<template>
    <div id="difusion" class="component">
        
        <div class="difu">
            <h4>{{tits[0][lang]}}</h4>
            <svg width="100%" height="16" xmlns="http://www.w3.org/2000/svg">
                <rect x="0" y="0" width="5" height="5" fill="#148979"/>
                <rect x="10" y="2" width="calc(100% - 10px)" height="2" fill="#fff"/>
            </svg>
            <div class="lista-entidades">
                <div class="entidad">
                    <h5>Cíclica [space·community·ecology]</h5>
                    <a :href="entidades.ciclica[lang].web" target="_blank"><font-awesome-icon icon="globe" /></a>
                    <a :href="entidades.ciclica[lang].tuit" target="_blank"><font-awesome-icon :icon="['fab', 'twitter-square']" /></a>
                    <a :href="entidades.ciclica[lang].linkedin" target="_blank"><font-awesome-icon :icon="['fab', 'linkedin']" /></a>
                </div>
            

                <div class="entidad">
                    <h5>Green Building Council España</h5>
                    <a :href="entidades.gbce.web" target="_blank"><font-awesome-icon icon="globe" /></a>
                    <a :href="entidades.gbce.tuit" target="_blank"><font-awesome-icon :icon="['fab', 'twitter-square']" /></a>
                    <a :href="entidades.gbce.linkedin" target="_blank"><font-awesome-icon :icon="['fab', 'linkedin']" /></a>
                </div>
            </div>
        </div>
        <div class="difu">
            <h4>{{tits[1][lang]}}</h4>
            <svg width="100%" height="16" xmlns="http://www.w3.org/2000/svg">
                <rect x="0" y="0" width="5" height="5" fill="#148979"/>
                <rect x="10" y="2" width="calc(100% - 10px)" height="2" fill="#fff"/>
            </svg>
            <ul class="press">
                <li v-for="news in prensa"><a :href="news.url" target="_blank">{{news.medio}}</a></li>
            </ul>
        </div>
        <div class="difu">
            <h4>La hora de GBCe</h4>
            <svg width="100%" height="16" xmlns="http://www.w3.org/2000/svg">
                <rect x="0" y="0" width="5" height="5" fill="#148979"/>
                <rect x="10" y="2" width="calc(100% - 10px)" height="2" fill="#fff"/>
            </svg>
            
            
            <div id="playeryt"></div>
        </div>
        <div class="difu">
            <h4>VII Congreso Edificios Energía Casi Nula</h4>
            <svg width="100%" height="16" xmlns="http://www.w3.org/2000/svg">
                <rect x="0" y="0" width="5" height="5" fill="#148979"/>
                <rect x="10" y="2" width="calc(100% - 10px)" height="2" fill="#fff"/>
            </svg>
            
            
            <div class="vimeo-wrapper">
                <div class="vimeo-container">
                    <iframe src="//player.vimeo.com/video/482528123?autoplay=1&" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                </div>
            </div>
        </div>
         
    </div>
</template>

<script>
import '../../node_modules/youtube-chapters/dist/css/youtube-chapters.css';
import YTC from 'youtube-chapters';
export default {

  name: 'Difusion',
  props:['lang'],
  data () {
    return {
        tits:[
            {'es':'Entidades impulsoras','en':'Driving entities','ca':'Entitats impulsores'},
            {'es':'Prensa digital','en':'Digital press','ca':'Premsa digital'}
        ],
    	entidades:{
    		ciclica:{
    			'es':{
    				web:'https://ciclica.eu/es/work/nace-el-pase-e-%c2%b7-pasaporte-del-edificio-un-instrumento-para-acelerar-la-rehabilitacion-del-parque-edificado-hacia-la-descarbonizacion/', 
    				tuit:'https://twitter.com/ciclica_arq',
                    linkedin:'https://www.linkedin.com/in/ciclica-arquitectura-253993186/'
    			},
    			'ca':{
    				web:'https://ciclica.eu/ca/work/neix-el-pas-e-%c2%b7-passaport-de-ledifici-un-instrument-per-accelerar-la-rehabilitacio-de-parc-edificat-cap-a-la-descarbonitzacio/',
    				tuit:'https://twitter.com/ciclica_arq',
                    linkedin:'https://www.linkedin.com/in/ciclica-arquitectura-253993186/'
    			},
    			'en':{
    				web:'https://ciclica.eu/en/work/the-pas-e-%c2%b7-building-passport-is-born-an-instrument-to-accelerate-the-renovation-of-the-built-up-park-towards-decarbonization/',
    				tuit:'https://twitter.com/ciclica_arq',
                    linkedin:'https://www.linkedin.com/in/ciclica-arquitectura-253993186/'
    			}
    		},
    		gbce:{
    			
    			web:'https://gbce.es/blog/2020/03/17/nace-el-pas-e-pasaporte-del-edificio/', 
    			tuit:'https://twitter.com/GBCEs/status/1239954526968385536',
                linkedin:'https://www.linkedin.com/company/green-building-council-espana/?originalSubdomain=ar'
    			
    			
    		},
    	},
    	prensa:[
            {
                medio:'E-ficiencia',
                url:'https://e-ficiencia.com/pasaporte-del-edificio-acelerar-rehabilitacion-energetica/'
            },
            {
                medio:'Construible.es',
                url:'https://www.construible.es/2020/03/20/pasaporte-edificio-pas-e-ofrece-hoja-ruta-individualizada-rehabilitacion-energetica-integral'
            },
            {
                medio:'CIC Construcción',
                url:'http://www.cicconstruccion.com/es/notices/2020/03/nace-el-pasaporte-del-edificio-para-el-fomento-de-la-rehabilitacion-de-edificios-72543.php#.XnOaBpP0kWp'
            },
            {
                medio:'Ecoconstrucción',
                url:'http://www.ecoconstruccion.net/noticias/llega-pas-e-pasaporte-del-edificio-la-hoja-de-ruta-para-la-rehabilitacion-eficiente-CVaxS'
            },
            {
                medio:'Climanoticias.com',
                url:'http://www.climanoticias.com/es/notices/2020/03/nace-el-pas-e-el-pasaporte-del-edificio-para-planes-de-rehabilitacion-52356.php'
            },
    		{
                medio:'Energias Renovables',
                url:'https://www.energias-renovables.com/ahorro/pase-nuevo-instrumento-para-acelerar-la-descarbonizacion-20200317'
            },
            {
                medio:'Idealista',
                url:'https://www.idealista.com/news/inmobiliario/vivienda/2020/03/20/781179-la-rehabilitacion-de-viviendas-se-profesionaliza-con-el-pasaporte-del-edificio?amp=1'
            },
    		{
                medio:'Pisos.com',
                url:'https://www.pisos.com/aldia/nace-el-pasaporte-del-edificio-para-el-fomento-de-la-rehabilitacion-de-edificios/1639712/'
            },
    		{
                medio:'Entrerayas',
                url:'https://entrerayas.com/2020/03/nace-el-pas-e-pasaporte-del-edificio/'
            },
    		{
                medio:'Interempresas',
                url:'https://www.interempresas.net/Construccion/Articulos/299288-Nace-el-PAS-E-Pasaporte-del-edificio.html'
            },
    		{
                medio:'Economiadehoy.es',
                url:'https://www.economiadehoy.es/nace-el-pas-e-pasaporte-del-edificio-un-instrumento-para-acelerar-la-rehabilitacion-del-parque-edificado-hacia-la-descarbonizacion'
            },
    		{
                medio:'Economía3',
                url:'https://economia3.com/2020/03/17/254573-nace-pas-e-para-acelerar-la-rehabilitacion-del-parque-edificado-a-la-descarbonizacion/'
            },
    		{
                medio:'Iniziar. Real estate',
                url:'https://iniziar.com/construccion/pas-e-pasaporte-del-edificio-instrumento-rehabilitacion-de-edificios/'
            },
    		{
                medio:'Facility. Management Services',
                url:'http://www.facilitymanagementservices.es/actualidad-y-novedades/actualidad-fm-s/nace-el-pas-e-pasaporte-del-edificio-un-instrumento-para-acelerar-la-rehabilitacion-del-parque-edificado-hacia-la-descarbonizacion'
            },
    		{
                medio:'Cemento hormigón. Revista técnica',
                url:'http://www.cemento-hormigon.com/Noticias/Noticia?id=33454'
            },
    		{
                medio:'Asociación Nacional de Empresas de Rehabilitación y Reforma',
                url:'https://www.anerr.es/2020/03/17/anerrnews_23927/'
            },
    		{
                medio:'Entretenimiento Bit',
                url:'http://entretenimientobit.com/propiedades/nace-el-pasaporte-del-inmueble-para-el-fomento-de-la-rehabilitacion-de-inmuebles/'
            },
    		
    		{
                medio:'Inmodiario',url:'https://www.inmodiario.com/135/28777/ciclica-green-building-council-espana-lanzan-pas-e-pasaporte-edificio.html'
            },
    		{
                medio:'Smartlighting',
                url:'https://smart-lighting.es/nace-pasaporte-edificio-pas-e/'
            },
            {
                medio:'Arquitectura Sostenible',
                url:'https://arquitectura-sostenible.es/pasaporte-para-mejorar-rehabilitacion-sostenible/'
            },
            {
                medio:'ReformANERR',
                url:'https://reformanerr.com/pasaporte-del-edificio-pas-e-para-dinamizar-la-rehabilitacion/'
            },
            {
                medio:'EjePrime',
                url:'https://www.ejeprime.com/m2/un-pasaporte-hacia-la-descarbonizacion-de-los-edificios.html#'
            },
            {
                medio:'Mapei',
                url:'https://www.mapei.com/es/es/blog/detalle/blog-news/2020/03/17/nace-el-pas-e-pasaporte-para-acelerar-la-rehabilitaci%C3%B3n-del-parque-edificado'
            },
            {
                medio:'Promateriales',
                url:'https://promateriales.com/nace-pas-e-pasaporte-del-edificio-instrumento-acelerar-la-rehabilitacion-del-parque-edificado-hacia-la-descarbonizacion/'
            },
            {
                medio:'Guinot Prunera',
                url:'http://www.guinotprunera.com/noticias/consejos-novedades/llega-el-pasaporte-del-edificio-instrumento-para-facilitar-la-rehabilitacion-energetica/'
            },
            {
                medio:'Obras Urbanas',
                url:'https://www.obrasurbanas.es/nace-el-pas-e/'
            },
            {
                medio:'Climatización y Confort',
                url:'https://climatizacion-y-confort.cdecomunicacion.es/noticias/sectoriales/39648/implantara-espana-pasaporte-energetico-para-renovacion-edificios'
            },
            {
                medio:'Inmobiliare',
                url:'https://inmobiliare.com/pas-e-el-pasaporte-para-edificios-que-busca-acelerar-su-descarbonizacion/'
            },
            {
                medio:'Construible.es',
                url:'https://www.construible.es/comunicaciones/comunicacion-pas-e-pasaporte-edificio-instrumento-rehabilitacion-profunda-pasos'
            },
            {
                medio:'Demarcación de Cuenca Colegio Oficial de Arquitectos de Castilla-La Mancha',
                url:'https://www.coacmcuenca.es/index.php/actualidad/163-pas-e-pasaporte-del-edificio-instrumento-para-la-rehabilitacion-profunda-por-pasos'
            },
            
    	],
        titvideo:[
            {'es':'Turno de preguntas','ca':'Torn de preguntes','en':'Question time'}
        ]
    }
  },
  mounted(){
    //video Youtube
    var options = {
      youtubeId: 'S-m3Zjjuh0M',
      id: 'soundtrack',
      loadingText: '<p>Cargando</p>',
      fluid: true,
      width: '100%',
      height: '550px',
      playerVars: {
        iv_load_policy: 3,
        showinfo: 0,
        modestbranding: 1,
        wmode: 'transparent'
      },
      showTime: false,
      chapters: [
        {
          'time': '01:00',
          'title': 'Dolores Huertas',
          'id': 'id1',
          'text': 'Dolores Huertas'
        },
        {
          'time': '14:00',
          'title': 'Albert Grau',
          'id': 'id2',
          'text': 'Albert Grau'
        },
        {
          'time': '32:00',
          'title': 'Joaquim Arcas',
          'id': 'id3',
          'text': 'Joaquim Arcas'
        },
        {
          'time': '49:10',
          'title': this.titvideo[0][this.lang],
          'id': 'id4',
          'text': this.titvideo[0][this.lang]
        },
      ]
    };

    YTC('#playeryt', options);  
  }
}
</script>

<style lang="css" scoped>
</style>