<template>
	<div id="apoyos" class="component">
		<div class="botones">
			<div class="boton"  target="_blank" @click="showListas">
				<div class="contador">{{contador}}</div>
				<transition name="fadein"><span class="tooltip" ></span></transition></div>
			<div class="logos">
				<!-- <a v-for="elem in institucions" :href="elem.url" target="_blank" :alt="elem.nom"><img :src="elem.logo"></a> -->
			</div>
		</div>
		<div class="intro">
			<div class="col">
				<h3 v-html="textoapoyo.titulo[lang]"></h3>
				<p>{{textoapoyo.p1[lang]}}</p>
                <p>{{textoapoyo.p2[lang]}}</p>
                <!-- <p><strong>{{textoapoyo.p3[lang]}}</strong></p> -->
			</div>
			<div class="col">
				<p v-html="textoapoyo.p4[lang]"></p>
                <p>{{textoapoyo.p5[lang]}}<br><strong>{{textoapoyo.p6[lang]}}</strong></p>
			</div>
		</div>
		<div class="botones bajo">
			<div class="boton">
				<button class="submit" type="submit" @click="showListas">
		        	<font-awesome-icon icon="list" />
		        	<transition name="fadein"><span class="tooltip" >{{textoapoyo.p3[lang]}}</span></transition>
		        </button>
		    </div>
		    <div class="boton">
				<button class="submit" type="submit" @click="showFormus">
		        	<font-awesome-icon icon="plus" />
		        	<transition name="fadein"><span class="tooltip" >{{textoapoyo.p6[lang]}}</span></transition>
		        </button>
		    </div>
		</div>
		<transition name="fadein">
            <div v-if="listas" class="modal-mask modal-lista">
                <div class="modal-wrapper">
                    <div class="modal-container">
                    	<button class="submit cerrar" type="submit" @click="cerrarListas">
					        	<font-awesome-icon icon="times" />
					    </button>
						<div class="listas">
							<div class="institucions">
								<h3>{{tituls[0][lang]}}</h3>
								<svg width="100%" height="16" xmlns="http://www.w3.org/2000/svg">
									<rect x="0" y="0" width="5" height="5" fill="#148979"/>
								  	<rect x="10" y="2" width="calc(100% - 10px)" height="2" fill="#fff"/>
								</svg>
								<ul class="lista">
									<li v-for="inst in institucions">
										<a :href="inst.url" target="_blank">
											<span v-html="inst.nom"></span>
											<img :src="inst.logo" :alt="inst.nom">
										</a>
									</li>
								</ul>
							</div>
							<!-- <div class="institucions">
								<h3>{{tituls[1][lang]}}</h3>
								<svg width="100%" height="16" xmlns="http://www.w3.org/2000/svg">
									<rect x="0" y="0" width="5" height="5" fill="#148979"/>
								  	<rect x="10" y="2" width="calc(100% - 30px)" height="2" fill="#fff"/>
								</svg>
								<ul class="lista">
									<li v-for="inst in institucions" v-if="inst.tipus=='empresa'">
										<a :href="inst.url">
											<img :src="inst.logo" :alt="inst.nom">
										</a>
									</li>
								</ul>
							</div> -->
							<div class="profesionals">
								<h3>{{tituls[2][lang]}}</h3>
								<svg width="100%" height="16" xmlns="http://www.w3.org/2000/svg">
									<rect x="0" y="0" width="5" height="5" fill="#148979"/>
								  	<rect x="10" y="2" width="calc(100% - 16px)" height="2" fill="#fff"/>
								</svg>
								<ul class="lista">
									<li v-for="prof in profesionals"><p class="profesional">{{prof.nom}}<br><span class="profesio">{{prof.profesio[lang]}} <span v-if="prof.empresa[lang]">| {{prof.empresa[lang]}}</span></span></p></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<transition name="fadein">
            <div v-if="formulario_suport" class="modal-mask modal-lista">
                <div class="modal-wrapper">
                    <div class="modal-container">
                    	
						<div class="formusuport">
							<h3>{{tituls[3][lang]}}</h3>
							<div  class="tabbuttons">
				  				<button v-for="formu in formusuport" :class="{active: tab==formu.key}" @click="changeTab(formu.key)">{{formu.name[lang]}}</button>
							</div>
							<svg class="tabsvg" width="100%" height="16" xmlns="http://www.w3.org/2000/svg">
									<rect x="0" y="0" width="5" height="5" fill="#148979"/>
								  	<rect x="10" y="2" width="calc(100% - 10px)" height="2" fill="#fff"/>
							</svg>
							<form v-if="tab==1" class="vue-form" @submit.prevent="sendEmpresa($event)">

								    <div class="error-message">
								      <p class="errorp" v-if="formulario_empresas.faltan">{{faltantext[lang]}}</p>
								      <p class="errorp" v-if="formulario_empresas.nomail">{{nomailtext[lang]}}</p>
								      <p class="errorp" v-if="formulario_empresas.grande">{{grandetext[lang]}}</p>
								      <p class="errorp" v-show="formulario_empresas.enviado">{{enviadotext[lang]}}</p>
								    </div>
								    

								    <div class="fieldset">
								    	<div class="col">
									      	<div class="grup">
									        	<label class="label" for="nombre">{{labels.nombre_empresa[lang]}}*</label>
									        	<input type="text" name="nombre" required="" v-model="formulario_empresas.nombre">
									      	</div>
									        <div class="grup">
									        	<label class="label" for="actividad">{{labels.actividad[lang]}}*</label>
									        	<input type="text" name="actividad" id="nombre" required="" v-model="formulario_empresas.actividad">
									      	</div>
									      	<div class="grup">
									        	<label class="label" for="ciudad">{{labels.ciudad[lang]}}*</label>
									        	<input type="text" name="ciudad" required="" v-model="formulario_empresas.ciudad">
									      	</div>
									      	<div class="grup">
									        	<label class="label" for="pais">{{labels.pais[lang]}}*</label>
									        	<input type="text" name="pais" required="" v-model="formulario_empresas.pais">
									      	</div>
									      	<div class="grup">
									        	<label class="label" for="logotipo" >{{labels.logotipo[lang]}}</label>
									        	<input type="file"  name="logotipo"ref="logotipo">
									      	</div>
									      	
									    </div>
									    <div class="col">
									    	<div class="grup">
									        	<label class="label" for="nombre_representante">{{labels.repre_nombre[lang]}}*</label>
									        	<input type="text" name="nombre_representante" id="nombre" required="" v-model="formulario_empresas.representante_nombre">
									      	</div><div class="grup">
									        	<label class="label" for="apellidos_representante">{{labels.repre_apellido[lang]}}*</label>
									        	<input type="text" name="apellidos_representante"  required="" v-model="formulario_empresas.representante_apellidos">
									      	</div>
									    	<div class="grup">
									        	<label class="label" for="email">{{labels.email[lang]}}*</label>
									        	<input type="email" name="email" id="email" required="" v-model="formulario_empresas.email">
									      	</div>
									      	<div class="grup">
									        	<label class="label" for="web">{{labels.web[lang]}}*</label>
									        	<input type="text" name="web" required="" v-model="formulario_empresas.web">
									      	</div>
									      	<div class="grup politica">
		                                        <input type="checkbox" name="politica" v-model="formulario_empresas.acepto">
		                                        <label class="label" for="politica">{{labels.acepto[lang]}}<a :href="'politica_'+lang+'.html'" target="_blank">{{labels.politica[lang]}} *</a></label>
		                                    </div>
									     </div>
									     
									      
									       
									     
									    
								      
								    </div>
								    <div class="textosuport" v-html="texto_empresas[lang]">
									</div>
								     <button class="submit" type="submit">
									        	<font-awesome-icon icon="paper-plane" />
									        	<transition name="fadein"><span class="tooltip" >{{labels.enviar[lang]}}</span></transition>
									 </button>
									 <button class="submit cerrar" type="submit" @click="cerrarFormus">
					        			<font-awesome-icon icon="times" />
					        			<transition name="fadein"><span class="tooltip" >{{labels.cancelar[lang]}}</span></transition>
					    			</button>
								    
							</form>
							<form v-if="tab==2" class="vue-form" @submit.prevent="sendProfesional($event)">

								    <div class="error-message">
								      <p class="errorp" v-if="formulario_profesionales.faltan">{{faltantext[lang]}}</p>
								      <p class="errorp" v-if="formulario_profesionales.nomail">{{nomailtext[lang]}}</p>
								      <p class="errorp" v-show="formulario_profesionales.enviado">{{enviadotext[lang]}}</p>
								    </div>

								    <div class="fieldset">
								    	<div class="col">
									      	<div class="grup">
									        	<label class="label" for="nombre">{{labels.nombre[lang]}}*</label>
									        	<input type="text" name="nombre" required="" v-model="formulario_profesionales.nombre">
									      	</div>
									        <div class="grup">
									        	<label class="label" for="apellidos">{{labels.apellidos[lang]}}*</label>
									        	<input type="text" name="apellidos" id="nombre" required="" v-model="formulario_profesionales.apellidos">
									      	</div>
									      	<div class="grup">
									        	<label class="label" for="profesion">{{labels.profesion[lang]}}*</label>
									        	<input type="text" name="profesion" required="" v-model="formulario_profesionales.profesion">
									      	</div>
									      	<div class="grup">
									        	<label class="label" for="empresa">{{labels.empresa[lang]}}</label>
									        	<input type="text" name="empresa" id="nombre" v-model="formulario_profesionales.empresa">
									      	</div>
									      	
									    </div>
									    <div class="col">
									    	<div class="grup">
									        	<label class="label" for="ciudad">{{labels.ciudad[lang]}}*</label>
									        	<input type="text" name="ciudad" required="" v-model="formulario_profesionales.ciudad">
									      	</div>
									      	<div class="grup">
									        	<label class="label" for="pais">{{labels.pais[lang]}}*</label>
									        	<input type="text" name="pais" required="" v-model="formulario_profesionales.pais">
									      	</div>
									    	<div class="grup">
									        	<label class="label" for="email">{{labels.email[lang]}}*</label>
									        	<input type="email" name="email" id="email" required="" v-model="formulario_profesionales.email">
									      	</div>
									      	<div class="grup">
									        	<label class="label" for="twitter">{{labels.twitter[lang]}}</label>
									        	<input type="text" name="twitter" v-model="formulario_profesionales.twitter">
									      	</div>
									      	<div class="grup politica">
		                                        <input type="checkbox" name="politica" v-model="formulario_profesionales.acepto">
		                                        <label class="label" for="politica">{{labels.acepto[lang]}}<a :href="'politica_'+lang+'.html'" target="_blank">{{labels.politica[lang]}} *</a></label>
		                                    </div>
									     </div>
									     
									      
									       
									     
									    
								      
								    </div>
								    <div class="textosuport" v-html="texto_profesional[lang]">
									     
									</div>
								     <button class="submit" type="submit">
									        	<font-awesome-icon icon="paper-plane" />
									        	<transition name="fadein"><span class="tooltip" >{{labels.enviar[lang]}}</span></transition>
									        </button>
									         <button class="submit cerrar" @click="cerrarFormus">
					        			<font-awesome-icon icon="times" />
					        			<transition name="fadein"><span class="tooltip" >{{labels.cancelar[lang]}}</span></transition>
					    			</button>
								    
							</form>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import Axios from 'axios'
import VueAxios from 'vue-axios'
import { institucions } from '../constants/institucions'
import { profesionals } from '../constants/profesionals'
export default {

  name: 'Apoyos',
  props:['lang'],
  data () {
    return {
    	contador:0,
    	titul:{'en':'Building Passport support network','es':'Red de apoyo al Pasaporte del edificio', 'ca':'Xarxa de suport al passaport de l\'edifici'},
    	tituls:[
    		{'en':'Institutions and companies','es':'Instituciones y empresas','ca':'Institucions i empreses'},
    		{'en':'Companies','es':'Empresas','ca':'Empreses'},
    		{'en':'Professionals','es':'Profesionales','ca':'Professionals'},
    		{'en':'Fill out this form to be part of the Building Passport Support Network','es':'Rellena este formulario para formar parte de la Red de apoyo al Pasaporte del edificio','ca':'Omple aquest formulari per formar part de la xarxa de suport al Passaport de l\'edifici'}
    	],
		institucions: institucions,
		profesionals: profesionals,
    	faltan:false,
      	nomail:false,
      	enviado:false,
      	formulario_empresas:{
      		nombre:'',
      		actividad:'',
      		ciudad:'',
      		pais:'',
      		representante_nombre:'',
      		representante_apellidos:'',
      		email:'',
      		logotipo:'',
      		web:'',
      		acepto:false,
      		enviado:false,
      		faltan:false,
      		nomail:false,
      		grande:false,

      	},
      	formulario_profesionales:{
      		nombre:'',
      		apellidos:'',
      		profesion:'',
      		empresa:'',
      		ciudad:'',
      		pais:'',
      		email:'',
      		twitter:'',
      		acepto:false,
      		enviado:false,
      		faltan:false,
      		nomail:false,
      	},
      	faltantext:{
      		'en':'Please, fill all the required fields.',
      		'es':'Por favor, rellena todos los campos obligatorios.',
      		'ca':'Si us plau, omple tots els camps obligatoris.'
      	},
      	grandetext:{
      		'en':'The file you are trying to upload is too big',
      		'es':'El archivo que intentas subir es demasiado grande',
      		'ca':'L\'arxiu que intentes pujar és massa gran'
      	},
      	labels:{
      		nombre:{'en':'Name','es':'Nombre','ca':'Nom'},
      		nombre_empresa:{'en':'Name of the isntitution or company','es':'Nombre de la institución o empresa','ca':'Nom de l\'institució o empresa'},
      		apellidos:{'en':'Last name','es':'Apellidos','ca':'Cognoms'},
      		actividad:{'en':'Activity','es':'Actividad','ca':'Activitat'},
      		profesion:{'en':'Degree, profession or position','es':'Titulación, profesión o cargo','ca':'Titulació, professió o càrrec'},
      		empresa:{'en':'Institution or company','es':'Institución o empresa','ca':'Institució o empresa'},
      		ciudad:{'en':'City','es':'Ciudad','ca':'Ciutat'},
      		pais:{'en':'Country','es':'País','ca':'País'},
      		web:{'en':'Website Address','es':'Dirección de la página web','ca':'Direcció de la pàgina web'},
      		repre_nombre:{'en':'Representative name','es':'Nombre representante','ca':'Nom representant'},
      		repre_apellido:{'en':'Representative last name','es':'Apellidos representante','ca':'Cognoms representant'},
      		email:{'en':'E-mail','es':'Correo electrónico','ca':'Correu electrònic'},
      		logotipo:{'en':'Logo (maximum size:2Mb)','es':'Logotipo (tamaño máximo:2Mb)','ca':'Logotip (grandària màxima:2Mb)'},
      		enviar:{'en':'Send','es':'Enviar','ca':'Enviar'},
      		acepto:{'en':'I accept the ','es': 'Acepto la ', 'ca':'Accepto la '},
            politica:{'en':'Privacy Policy', 'es':'Política de privacidad','ca':'Política de privacitat'},
            cancelar:{'en':'Cancel','es':'Cancelar','ca':'Cancel·lar'},
            twitter:{'es':'Perfil de Twitter','en':'Twitter profile','ca':'Perfil de Twitter'}
      	},
      	enviadotext:{
      		'en':'Your request has been sent correctly, thank you very much!',
      		'es':'Tu solicitud se ha enviado correctamente, ¡muchas gracias!',
      		'ca':'La teva sol·licitud s\'ha enviat correctament, moltes gràcies!'
      	},
      	nomailtext:{
            'en':'The email address is not valid',
            'es':'La dirección de correo electrónico no es válida.',
            'ca':'La direcció de correu electrònic no és vàlida'
        },
        textoapoyo:{
        	titulo: {
        		'en':'Building Passport Support Network ',
        		'es':'Red de apoyo al Pasaporte del edificio',
        		'ca':'Xarxa de suport al Passaport de l’edifici',
        	},
        	p1: {
        		'en':'Institutions, companies and professionals belonging to or with strong ties to the building sector support the initiative of introducing the Building Passport in Spain.',
        		'es':'Instituciones, empresas y profesionales pertenecientes o con fuertes vínculos con el sector de la edificación apoyan la iniciativa de implantar el Pasaporte del edificio en España.',
        		'ca':'Institucions, empreses i professionals pertanyents o amb forts vincles amb el sector de l\'edificació donen suport a la iniciativa d’implantar el Passaport de l\'edifici a Espanya.',
        	},
        	p2: {
        		'en':'Together they form a Support Network on which to begin the process involved in the definition and implementation of this new instrument.',
        		'es':'Conjuntamente forman la Red de apoyo sobre la que iniciar el proceso participativo de definición e implementación de este nuevo instrumento europeo.',
        		'ca':'Conjuntament formen la Xarxa de suport a través de la qual iniciar el procés participatiu de definició i implementació d\'aquest nou instrument europeu.',
        	},
        	p3: {
        		'en':'Complete list',
        		'es':'Lista completa',
        		'ca':'Llista completa',
        	},
        	p4: {
        		'en':'Only by the collaboration and consensus among all agents is it possible to achieve a useful and effective model of the Building Passport that allows urban regeneration to be realized in terms of quality of life and sustainability.',
        		'es':'Solo desde la colaboración y el consenso entre todos los agentes es posible alcanzar un modelo de Pasaporte del edificio útil y eficaz que permita hacer realidad la regeneración urbana en clave de calidad de vida y sostenibilidad.',
        		'ca':'Només des de la col·laboració i el consens entre tots els agents és possible arribar a un model de Passaport de l\'edifici útil i eficaç que permeti fer realitat la regeneració urbana en clau de qualitat de vida i sostenibilitat.',
        	},
        	p5: {
        		'en':'We would like to have your support and participation',
        		'es':'Queremos contar con tu apoyo y participación:',
        		'ca':'Volem comptar amb el teu suport i participació:',
        	},
        	p6: {
        		'en':'Join the Network!',
        		'es':'¡únete a la Red!',
        		'ca':'Uneix-te a la Xarxa!',
        	}
        },
        destacados:{

        },
        listas:false,
        formusuport:[
        	{key:1, name:{'en':'Institutions and companies','es':'Instituciones y empresas','ca':'Institucions i empreses'}},
        	{key:2, name:{'en':'Professionals','es':'Profesionales','ca':'Professionals'}}
        ],
        tab:1,
        formulario_suport:false,
        texto_empresas:{
        	'en':'By submitting this form I am applying on behalf of the institution or company that I represent to join the Support Network of the Building Passport and I authorize the disclosure of the data on the PAS-E website; excluding the e-mail address.<br>As a member of the Network I support  to the initiative to start a debate on how this new european instrument should be defined and implemented in Spain and I manifest predisposition to participate in the same face-to-face or telematically, without obligation. Under no circumstances the joining involves express and complete validation of the proposed passport model developed by Cíclica in cooperation with GBCe.<br>Still having doubts? Contact us through the form or by the e-mail address <a href="mailto:info@pas-e.eu">info@pas-e.eu</a>',
        	'es':'Mediante la presentación de este formulario solicito la adhesión de la institución o empresa que represento a la Red de apoyo al Pasaporte del edificio y autorizo la publicación de los datos en la web de PAS-E a excepción del correo electrónico.<br>Como miembro de la Red doy apoyo a la iniciativa de abrir un debate sobre cómo debería ser definido e implementado este nuevo instrumento europeo y manifiesto predisposición a participar en el mismo presencial o telemáticamente, sin carácter de obligatoriedad. En ningún caso la adhesión conlleva expresa y completa validación de la propuesta de modelo de pasaporte elaborada por Cíclica en colaboración con GBCe.<br>¿Aún tienes dudas? Ponte en contacto con nosotros mediante el formulario o a través del correo electrónico <a href="mailto:info@pas-e.es">info@pas-e.es</a>',
        	'ca':'Mitjançant la presentació d\'aquest formulari sol·licito l\'adhesió de la institució o empresa que represento a la Xarxa de suport al Passaport de l\'edifici i autoritzo la publicació de les dades al web de PAS-E a excepció el correu electrònic.<br>Com a membre de la Xarxa dono suport a la iniciativa d\'obrir un debat sobre com hauria de ser definit i implementat aquest nou instrument europeu i manifesto predisposició a participar en el mateix presencial o telemàticament, sense caràcter d\'obligatorietat. En cap cas l\'adhesió comporta expressa i completa validació de la proposta de model de passaport elaborada per Cíclica en col·laboració amb GBCe.<br>Encara tens dubtes? Posat en contacte amb nosaltres mitjançant el formulari o a través del correu electrònic <a href="mailto:info@pas-e.cat">info@pas-e.cat</a>'
        },
        texto_profesional:{
        	'en':'By submitting this form I am applying to join the Support Network of the Building Passport as a professional and I authorize the disclosure of the data on the PAS-E website; excluding the e-mail adress.  <br>As a member of the Network I support  to the initiative to start a debate on how this new european instrument should be defined and implemented in Spain and I manifest predisposition to participate in the same face-to-face or telematically, without obligation. Under no circumstances the joining involves express and complete validation of the proposed passport model developed by Cíclica in cooperation with GBCe.<br>Still having doubts? Contact us through the form or by the e-mail address <a href="mailto:info@pas-e.cat">info@pas-e.cat</a>',
        	'es':'Mediante la presentación de este formulario solicito mi adhesión como profesional a la Red de apoyo al Pasaporte del edificio y autorizo la publicación de los datos en la web de PAS-E a excepción del correo electrónico.<br>Como miembro de la Red doy apoyo a la iniciativa de abrir un debate sobre cómo debería ser definido e implementado este nuevo instrumento europeo y manifiesto predisposición a participar en el mismo presencial o telemáticamente, sin carácter de obligatoriedad. En ningún caso la adhesión conlleva expresa y completa validación de la propuesta de modelo de pasaporte elaborada por Cíclica en colaboración con GBCe.<br>¿Aún tienes dudas? Ponte en contacto con nosotros mediante el formulario o a través del correo electrónico <a href="mailto:info@pas-e.es">info@pas-e.es</a>',
        	'ca':'Mitjançant la presentació d\'aquest formulari sol·licito la meva adhesió com a professional a la Xarxa de suport al Passaport de l\'edifici i autoritzo la publicació de les dades al web de PAS-E a excepció el correu electrònic.<br>Com a membre de la Xarxa dono suport a la iniciativa d\'obrir un debat sobre com hauria de ser definit i implementat aquest nou instrument europeu i manifesto predisposició a participar en el mateix presencial o telemàticament, sense caràcter d\'obligatorietat. En cap cas l\'adhesió comporta expressa i completa validació de la proposta de model de passaport elaborada per Cíclica en col·laboració amb GBCe.<br>Encara tens dubtes? Posat en contacte amb nosaltres mitjançant el formulari o a través del correu electrònic <a href="mailto:info@pas-e.cat">info@pas-e.cat</a>'
        }

    }
  },
  methods:{
  	sendEmpresa(event) {
  		var f = this.formulario_empresas;
  		var logotipo = this.$refs.logotipo.files[0];
  		var formData = new FormData(event.target);
  		formData.append('idioma',this.lang);
  		formData.append('tipo','Empresa o institució');
  		//formData.append('logotipo',logotipo);
  		if(f.nombre==''||f.actividad==''||f.ciudad==''||f.pais==''||f.representante_nombre==''||f.representante_apellidos==''||f.email==''||f.acepto==false ||f.web==''){
  			f.faltan = true;
		}
		else {
			function validateEmail(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            }
			f.faltan = false;
	  	    var qs = require('qs');
	  	    for (var pair of formData.entries()) {
	            console.log(pair[0]+ ', ' + pair[1]); 
	        }
	  	    if(validateEmail(f.email)){
	  	    	f.nomail = false;
	  	    	if(logotipo!=undefined&&logotipo['size']>2000000){
	  	    		f.grande = true
	  	    		return 
	  	    	} else {
	  	    		f.grande = false;
	  	    	}
		  	    	Axios.post(
		                'http://ciclica.eu/apoyo-pase.php',
		                formData,
		                {headers: { "Content-Type": "multipart/form-data" }}
		                  
		            ).then(response => {
	                	console.log(response.data)
	                	f.nombre = '';
	                	f.actividad = '';
	                	f.ciudad = '';
	                	f.pais = '';
	                	f.representante_nombre = '';
	                	f.representante_apellidos = '';
	                	f.email = '';
	                	f.enviado = true;
	                	f.acepto = false;
	                	f.web = '';
	                	logotipo = ''
	                	this.$refs.logotipo.value = '';
	                })
	             
	               
	                .catch(response => console.log('submit error: '+ response))

	  	    } else {
	  	    	f.nomail = true;
	  	    }
	  		
		}
	},
	sendProfesional() {
  		var f = this.formulario_profesionales;
  		var formData = new FormData(event.target);
  		formData.append('idioma',this.lang);
  		formData.append('tipo','Profesional');
  		
  		if(f.nombre==''||f.apellidos==''||f.ciudad==''||f.pais==''||f.profesion==''||f.empresa==''||f.email==''||f.acepto==false){
  			f.faltan = true;
		}
		else {
			function validateEmail(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            }
			f.faltan = false;
	  	    var qs = require('qs');
	  	    
	  	    if(validateEmail(f.email)){
	  	    	f.nomail = false;
	  	    	
	  	    	Axios.post(
		                'http://ciclica.eu/apoyo-pase.php',
		                formData,
		                {headers: { "Content-Type": "multipart/form-data" }}
		                  
		            ).then(response => {
	                	f.nombre='';
	                	f.apellidos='';
	                	f.profesion='';
	                	f.empresa = '';
	                	f.ciudad='';
	                	f.pais='';
	                	f.email='';
	                	f.twitter='';
	                	f.enviado = true;
	                	f.acepto = false;
	                })
	             
	               
	                .catch(response => console.log('submit error: '+ response))

	  	    } else {
	  	    	f.nomail = true;
	  	    }
	  		
		}
	},
	showListas(){
		this.listas = true;
		document.querySelector('body').style.overflow = 'hidden'
	},
	cerrarListas(){
		this.listas = false;
		document.querySelector('body').style.overflow = 'auto'
	},
	showFormus(){
		this.formulario_suport = true;
		document.querySelector('body').style.overflow = 'hidden'
	},
	cerrarFormus(){
		this.formulario_suport = false;
		this.formulario_profesionales.enviado = false;
		this.formulario_empresas.enviado = false;
		document.querySelector('body').style.overflow = 'auto'
	},
	changeTab(key){
		this.tab = key;
	}
  },
  mounted(){
  	var heightInit= window.innerHeight;
    var padding = innerHeight/4;
    var apoyos = document.querySelector('#apoyos');
    if(apoyos.offsetHeight<=heightInit){
    	 document.querySelector('#apoyos').style.height=`${heightInit}px`;
    }
    this.contador = this.institucions.length + this.profesionals.length
    
  }
}
</script>

<style lang="css" scoped>
</style>